import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextFieldInput, ButtonInput } from "../../../components";
import { useUpdateUsuarioMutation } from "../../../store/services/neomedApi";
import { AuthContext } from "../../../context";

const schema = Yup.object().shape({
  nome: Yup.string().required("Campo Obrigatório"),
  telefone: Yup.string().matches(/^[0-9()-]*$/, "Formato Errado!"),
  celular: Yup.string().matches(/^[0-9()-]*$/, "Formato Errado!"),
});

const PerfilEditForm = ({ toggleUserEditModal, userData }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      nome: userData.nome ? userData.nome : "",
      telefone: userData.telefone ? userData.telefone : "",
      celular: userData.celular ? userData.celular : "",
    },
    resolver: yupResolver(schema),
  });
  const [updateUser] = useUpdateUsuarioMutation();
  const auth = useContext(AuthContext);

  const editPerfilHandler = async (data) => {
    const usuarioObj = { ...data };

    const { data: response } = await updateUser({
      usuarioId: auth?.user?._id,
      usuarioObj,
    });

    if (response?.status === "success") {
      auth?.setUser((prevState) => {
        const prevUser = { ...prevState };

        const user = {
          email: prevUser.email,
          ...response.data?.doc,
        };

        return user;
      });
    }

    toggleUserEditModal();
  };

  return (
    <form
      onSubmit={handleSubmit(editPerfilHandler)}
      className="grid grid-cols-1 gap-9 p-4"
    >
      <TextFieldInput
        name={"nome"}
        control={control}
        label="Nome"
        error={errors?.nome}
      />

      <TextFieldInput
        name={"telefone"}
        control={control}
        label="Telefone"
        type={"text"}
        error={errors?.telefone}
        mask={"(99)9999-9999"}
      />

      <TextFieldInput
        name={"celular"}
        control={control}
        label="Celular"
        type={"text"}
        error={errors?.celular}
        mask={"(99)99999-9999"}
      />

      <ButtonInput styles={"col-span-full justify-self-center"} type={"submit"}>
        Editar
      </ButtonInput>
    </form>
  );
};

export default PerfilEditForm;

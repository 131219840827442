import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import { AuthContext } from "../../context";

const Root = () => {
  const auth = useContext(AuthContext);

  return (
    <>
      {auth?.isLoading && (
        <div className="relative h-screen">
          <div className="absolute left-2/4 top-2/4 transform -translate-x-2/4 -translate-y-2/4 text-shade-green">
            <div className="border-t-transparent w-16 h-16 border-4 border-primary-green border-solid rounded-full animate-spin" />
          </div>
        </div>
      )}
      {auth?.user && auth?.isLoading === false && <Header />}
      {auth?.isLoading === false && (
        <main>
          <Outlet />
        </main>
      )}
    </>
  );
};

export default Root;

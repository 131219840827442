import React from "react";

const SectionList = ({ children }) => {
  return (
    <ul className="max-w-7xl mx-auto my-0 px-8 py-4 grid normal:grid-cols-3 small:grid-cols-2 phone:grid-cols-1 gap-x-9 gap-y-4">
      {children}
    </ul>
  );
};

export default SectionList;

import React, { useState } from "react";
import { Stepper } from "../../../components";
import PacienteCreateForm from "../PacienteCreateForm/PacienteCreateForm";
import PacienteDetailsForm from "../PacienteDetailsForm/PacienteDetailsForm";

const PacienteStepperForm = ({
  togglePacientesCreateModal,
  togglePacienteEditModal,
  pacienteData,
  refetch,
}) => {
  const [stepNumber, setStepNumber] = useState(0);
  const [stepOneData, setStepOneData] = useState({});

  const next = (stepOneData) => {
    setStepNumber((prevState) => prevState + 1);
    setStepOneData(stepOneData);
  };

  return (
    <Stepper
      stepNumber={stepNumber}
      labels={["Identidade & Documentos", "Contatos & Endereço"]}
    >
      <PacienteCreateForm next={next} pacienteData={pacienteData} />
      <PacienteDetailsForm
        stepOneData={stepOneData}
        togglePacienteCreateModal={togglePacientesCreateModal}
        togglePacienteEditModal={togglePacienteEditModal}
        pacienteData={pacienteData}
        refetch={refetch}
      />
    </Stepper>
  );
};

export default PacienteStepperForm;

import React from "react";

const ButtonInput = ({ styles, type, disabled, children, onClick }) => {
  return (
    <button
      className={`flex justify-center items-center gap-2 bg-primary-green px-4 py-2 text-primary-black border-none rounded-md shadow-md transition-all duration-200 ease-out hover:bg-shade-green ${
        styles ? styles : ""
      }`}
      type={type}
      disabled={disabled ? true : false}
      onClick={onClick ? onClick : null}
    >
      {children}
    </button>
  );
};

export default ButtonInput;

import React from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  CogIcon,
  HeartIcon,
  UserGroupIcon,
  IdentificationIcon,
} from "@heroicons/react/solid";

const HeaderAdminMenu = ({ navigate }) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <Menu as="div" className="relative">
          <Menu.Button
            title="Opções de admin"
            className={`${
              active
                ? "bg-primary-green text-light-green"
                : "text-primary-black"
            } flex items-center gap-2 rounded-md w-full px-1 py-1`}
          >
            <CogIcon className="w-5 h-5 fill-current" />
            <span>Admin Options</span>
          </Menu.Button>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items className="flex flex-col bg-white shadow-md rounded-lg w-56 px-1 py-1 absolute right-20 z-10">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => navigate("/especializacoes")}
                    title="Especialidades"
                    className={`${
                      active
                        ? "bg-primary-green text-light-green"
                        : "text-primary-black"
                    } flex items-center gap-2 rounded-md px-1 py-1`}
                  >
                    <IdentificationIcon className="w-5 h-5 fill-current" />
                    <span>Especialidades</span>
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => navigate("/medicos")}
                    title="Medicos"
                    className={`${
                      active
                        ? "bg-primary-green text-light-green"
                        : "text-primary-black"
                    } flex items-center gap-2 rounded-md px-1 py-1`}
                  >
                    <HeartIcon className="w-5 h-5 fill-current" />
                    <span>Medicos</span>
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => navigate("/pacientes")}
                    title="Pacientes"
                    className={`${
                      active
                        ? "bg-primary-green text-light-green"
                        : "text-primary-black"
                    } flex items-center gap-2 rounded-md px-1 py-1`}
                  >
                    <UserGroupIcon className="w-5 h-5 fill-current" />
                    <span>Pacientes</span>
                  </button>
                )}
              </Menu.Item>

              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => navigate("/admins")}
                    title="Admins"
                    className={`${
                      active
                        ? "bg-primary-green text-light-green"
                        : "text-primary-black"
                    } flex items-center gap-2 rounded-md px-1 py-1`}
                  >
                    <CogIcon className="w-5 h-5 fill-current" />
                    <span>Admins</span>
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </Menu.Item>
  );
};

export default HeaderAdminMenu;

import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { convertToRaw, EditorState } from "draft-js";
import { SectionTitle, Modal, TextEditorInput } from "../../components";
import ProntuarioMenu from "./ProntuarioMenu/ProntuarioMenu";
import {
  useGetAllFichasQuery,
  useGetAllAtestadosQuery,
  useGetAllReceitasQuery,
  useCreateFichaMutation,
  useCreateAtestadoMutation,
  useCreateReceitaMutation,
} from "../../store/services/neomedApi";
import { AuthContext } from "../../context";
import ProntuarioStepper from "./ProntuarioStepper/ProntuarioStepper";

const Prontuario = () => {
  const [fichaForm, setFichaForm] = useState(false);
  const [fichaH, setFichaH] = useState(false);
  const [fichasList, setFichas] = useState([]);
  const [receituarioForm, setReceituarioForm] = useState(false);
  const [receituarioH, setReceituarioH] = useState(false);
  const [receitasList, setReceitas] = useState([]);
  const [atestadoForm, setAtestadoForm] = useState(false);
  const [atestadoH, setAtestadoH] = useState(false);
  const [atestadosList, setAtestados] = useState([]);
  const [editorFichaState, setEditorFichaState] = useState(
    EditorState.createEmpty()
  );
  const [editorReceituarioState, setEditorReceituarioState] = useState(
    EditorState.createEmpty()
  );
  const [editorAtestadoState, setEditorAtestadoState] = useState(
    EditorState.createEmpty()
  );
  const { id } = useParams();
  const auth = useContext(AuthContext);
  const { data: fichas, isLoading: loadingFichas } = useGetAllFichasQuery({
    paramsObj: { pacienteRef: id, medicoRef: auth?.user?._id },
  });
  const { data: atestados, isLoading: loadingAtestados } =
    useGetAllAtestadosQuery({
      paramsObj: { pacienteRef: id, medicoRef: auth?.user?._id },
    });
  const { data: receitas, isLoading: loadingReceitas } = useGetAllReceitasQuery(
    {
      paramsObj: { pacienteRef: id, medicoRef: auth?.user?._id },
    }
  );
  const [createFicha] = useCreateFichaMutation();
  const [createAtestado] = useCreateAtestadoMutation();
  const [createReceita] = useCreateReceitaMutation();

  useEffect(() => {
    if (fichas?.results !== 0) {
      setFichas(fichas?.data?.docs);
    }
  }, [fichas]);

  useEffect(() => {
    if (atestados?.results !== 0) {
      setAtestados(atestados?.data?.docs);
    }
  }, [atestados]);

  useEffect(() => {
    if (receitas?.results !== 0) {
      setReceitas(receitas?.data?.docs);
    }
  }, [receitas]);

  const toggleFichaForm = () => {
    setFichaForm((prevState) => !prevState);
  };

  const toggleFichaH = () => {
    setFichaH((prevState) => !prevState);
  };

  const toggleReceituarioForm = () => {
    setReceituarioForm((prevState) => !prevState);
  };

  const toggleReceituarioH = () => {
    setReceituarioH((prevState) => !prevState);
  };

  const toggleAtestadoForm = () => {
    setAtestadoForm((prevState) => !prevState);
  };

  const toggleAtestadoH = () => {
    setAtestadoH((prevState) => !prevState);
  };

  const createFichaHandler = () => {
    const contentState = editorFichaState.getCurrentContent();

    const Obj = {
      medicoRef: auth?.user?._id,
      pacienteRef: id,
      orientacao: JSON.stringify(convertToRaw(contentState)),
    };

    const medicoData = {
      medicoNome: auth?.user?.nome,
      medicoCrm: auth?.user?.medicoInfo?.crm,
    };

    return { contentState, Obj, medicoData };
  };

  const createReceituarioHandler = () => {
    const contentState = editorReceituarioState.getCurrentContent();

    const Obj = {
      medicoRef: auth?.user?._id,
      pacienteRef: id,
      orientacao: JSON.stringify(convertToRaw(contentState)),
    };

    const medicoData = {
      medicoNome: auth?.user?.nome,
      medicoCrm: auth?.user?.medicoInfo?.crm,
    };

    return { contentState, Obj, medicoData };
  };

  const createAtestadoHandler = () => {
    const contentState = editorAtestadoState.getCurrentContent();

    const Obj = {
      medicoRef: auth?.user?._id,
      pacienteRef: id,
      orientacao: JSON.stringify(convertToRaw(contentState)),
    };

    const medicoData = {
      medicoNome: auth?.user?.nome,
      medicoCrm: auth?.user?.medicoInfo?.crm,
    };

    return { contentState, Obj, medicoData };
  };

  return (
    <section className="pt-6">
      <SectionTitle>Prontuário</SectionTitle>

      <div className="max-w-7xl mx-auto my-0 px-8 py-4 grid normal:grid-cols-3 small:grid-cols-2 phone:grid-cols-1 gap-x-9 gap-y-4">
        <div className="bg-white rounded-md shadow-md">
          {!loadingFichas && (
            <div className="flex items-center justify-between p-4">
              <p className="text-xl text-shade-black font-bold">Fichas</p>
              <ProntuarioMenu
                historicoFunc={toggleFichaH}
                fichaForm={toggleFichaForm}
              />

              <Modal openState={fichaH} closeFunc={toggleFichaH}>
                <ProntuarioStepper
                  prontuarioLista={fichasList}
                  toggleFunc={toggleFichaH}
                />
              </Modal>

              <Modal openState={fichaForm} closeFunc={toggleFichaForm}>
                <TextEditorInput
                  editorState={editorFichaState}
                  setEditorState={setEditorFichaState}
                  handleOnChange={createFichaHandler}
                  readOnly={false}
                  pdfOnly={false}
                  apiAction={createFicha}
                  toggleFunc={toggleFichaForm}
                  incrementFunc={setFichas}
                />
              </Modal>
            </div>
          )}
        </div>

        <div className="bg-white rounded-md shadow-md">
          {!loadingReceitas && (
            <div className="flex items-center justify-between p-4">
              <p className="text-xl text-shade-black font-bold">Receituários</p>
              <ProntuarioMenu
                historicoFunc={toggleReceituarioH}
                fichaForm={toggleReceituarioForm}
              />

              <Modal openState={receituarioH} closeFunc={toggleReceituarioH}>
                <ProntuarioStepper
                  prontuarioLista={receitasList}
                  toggleFunc={toggleReceituarioH}
                />
              </Modal>

              <Modal
                openState={receituarioForm}
                closeFunc={toggleReceituarioForm}
              >
                <TextEditorInput
                  editorState={editorReceituarioState}
                  setEditorState={setEditorReceituarioState}
                  handleOnChange={createReceituarioHandler}
                  readOnly={false}
                  pdfOnly={false}
                  apiAction={createReceita}
                  toggleFunc={toggleReceituarioForm}
                  incrementFunc={setReceitas}
                />
              </Modal>
            </div>
          )}
        </div>

        <div className="bg-white rounded-md shadow-md">
          {!loadingAtestados && (
            <div className="flex items-center justify-between p-4">
              <p className="text-xl text-shade-black font-bold">Atestados</p>
              <ProntuarioMenu
                historicoFunc={toggleAtestadoH}
                fichaForm={toggleAtestadoForm}
              />

              <Modal openState={atestadoH} closeFunc={toggleAtestadoH}>
                <ProntuarioStepper
                  prontuarioLista={atestadosList}
                  toggleFunc={toggleAtestadoH}
                />
              </Modal>

              <Modal openState={atestadoForm} closeFunc={toggleAtestadoForm}>
                <TextEditorInput
                  editorState={editorAtestadoState}
                  setEditorState={setEditorAtestadoState}
                  handleOnChange={createAtestadoHandler}
                  readOnly={false}
                  pdfOnly={false}
                  apiAction={createAtestado}
                  toggleFunc={toggleAtestadoForm}
                  incrementFunc={setAtestados}
                />
              </Modal>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Prontuario;

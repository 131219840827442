import React from "react";
import { useUpdateAgendamentoMutation } from "../../../store/services/neomedApi";

const AgendamentoCancelForm = ({
  toggleCancelAgendamentoModal,
  agendamentoId,
  refetch,
}) => {
  const [updateAgendamento] = useUpdateAgendamentoMutation();

  const cancelAgendamentoHandler = async () => {
    const agendamentoObj = {
      andamento: "Cancelado",
    };

    const { data: response } = await updateAgendamento({
      agendamentoId,
      agendamentoObj,
    });

    if (response?.status === "success") {
      refetch();
    }

    toggleCancelAgendamentoModal();
  };

  return (
    <div className="flex flex-col gap-9 p-4">
      <p className="self-center">Tem Certeza ?</p>
      <button
        type="button"
        className="self-center bg-primary-red rounded-md p-4 text-sm text-primary-black font-medium tracking-widest"
        onClick={cancelAgendamentoHandler}
      >
        CANCELAR
      </button>
    </div>
  );
};

export default AgendamentoCancelForm;

import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextFieldInput, ButtonInput } from "../../../components";
import {
  useCreateUnidadeMutation,
  useUpdateUnidadeMutation,
} from "../../../store/services/neomedApi";

const schema = Yup.object().shape({
  telefone: Yup.string().matches(/^[0-9()-]*$/, "Formato Errado!"),
  celular: Yup.string().matches(/^[0-9()-]*$/, "Formato Errado!"),
  estado: Yup.string().required("Campo Obrigatorio!"),
  cidade: Yup.string().required("Campo Obrigatorio!"),
  bairro: Yup.string().required("Campo Obrigatorio!"),
  rua: Yup.string().required("Campo Obrigatorio!"),
  numero: Yup.string().required("Campo Obrigatorio!"),
  cep: Yup.string()
    .matches(/^([0-9]{5})-?([0-9]{3})$/, "Formato errado!")
    .required("Campo Obrigatorio!"),
});

const UnidadeDetailsForm = ({
  stepOneData,
  toggleUnidadeCreateModal,
  toggleUnidadeEditModal,
  unidadeData,
  refetch,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      telefone: unidadeData ? unidadeData.telefone : "",
      celular: unidadeData ? unidadeData.celular : "",
      estado: unidadeData ? unidadeData.estado : "",
      cidade: unidadeData ? unidadeData.cidade : "",
      bairro: unidadeData ? unidadeData.bairro : "",
      rua: unidadeData ? unidadeData.rua : "",
      numero: unidadeData ? unidadeData.numero : "",
      cep: unidadeData ? unidadeData.cep : "",
    },
    resolver: yupResolver(schema),
  });
  const [createUnidade] = useCreateUnidadeMutation();
  const [updateUnidade] = useUpdateUnidadeMutation();

  const createUnidadeHandler = async (data) => {
    const unidadeObj = {
      ...stepOneData,
      ...data,
    };

    const { data: response } = await createUnidade({ unidadeObj });

    if (response?.status === "success") {
      refetch();
    }

    toggleUnidadeCreateModal();
  };

  const editUnidadeHandler = async (data) => {
    const unidadeObj = {
      ...stepOneData,
      ...data,
    };

    const { data: response } = await updateUnidade({
      unidadeId: unidadeData._id,
      unidadeObj,
    });

    if (response?.status === "success") {
      refetch();
    }

    toggleUnidadeEditModal();
  };

  return (
    <form
      onSubmit={
        unidadeData
          ? handleSubmit(editUnidadeHandler)
          : handleSubmit(createUnidadeHandler)
      }
      className="grid normal:grid-cols-2 small:grid-cols-2 phone:grid-cols-1 gap-9 p-4"
    >
      <TextFieldInput
        name={"telefone"}
        control={control}
        label="Telefone: DDD..."
        type={"text"}
        error={errors?.telefone}
        mask={"(99)9999-9999"}
      />

      <TextFieldInput
        name={"celular"}
        control={control}
        label="Celular: DDD..."
        type={"text"}
        error={errors?.celular}
        mask={"(99)99999-9999"}
      />

      <TextFieldInput
        name={"estado"}
        control={control}
        label="Estado"
        type={"text"}
        error={errors?.estado}
      />

      <TextFieldInput
        name={"cidade"}
        control={control}
        label="Cidade"
        type={"text"}
        error={errors?.cidade}
      />

      <TextFieldInput
        name={"bairro"}
        control={control}
        label="Bairro"
        type={"text"}
        error={errors?.bairro}
      />

      <TextFieldInput
        name={"rua"}
        control={control}
        label="Rua"
        type={"text"}
        error={errors?.rua}
      />

      <TextFieldInput
        name={"numero"}
        control={control}
        label="Numero"
        type={"text"}
        error={errors?.numero}
      />

      <TextFieldInput
        name={"cep"}
        control={control}
        label="cep"
        type={"text"}
        error={errors?.cep}
        mask={"99999-999"}
      />

      <ButtonInput styles={"col-span-full justify-self-center"} type={"submit"}>
        {unidadeData ? "EDITAR" : "CADASTRAR"}
      </ButtonInput>
    </form>
  );
};

export default UnidadeDetailsForm;

import React, { useState, useEffect } from "react";
import {
  startOfWeek,
  startOfMonth,
  parseISO,
  endOfWeek,
  endOfMonth,
  subDays,
  isBefore,
  isSameDay,
  addDays,
  format,
  isToday,
  startOfDay,
  getTime,
} from "date-fns";
import CalendarHeader from "./CalendarHeader/CalendarHeader";

const Calendar = ({ value, onChange, onSelectDay, diasUnidade }) => {
  const [currentCalendar, setCurrentCalendar] = useState([]);

  useEffect(() => {
    const startDay = startOfWeek(startOfMonth(parseISO(value)));
    const endDay = endOfWeek(endOfMonth(parseISO(value)));
    let day = subDays(startDay, 1);
    const calendar = [];

    while (isBefore(day, endDay) && !isSameDay(day, endDay)) {
      calendar.push(
        Array(7)
          .fill(0)
          // eslint-disable-next-line no-loop-func
          .map(() => {
            day = addDays(day, 1);
            return day;
          })
      );
    }

    setCurrentCalendar(calendar);
  }, [value]);

  return (
    <div className="relative drop-shadow rounded-md overflow-hidden">
      <CalendarHeader value={value} onChange={onChange} />

      <div className="flex justify-around items-center bg-white h-20">
        {["d", "s", "t", "q", "q", "s", "s"].map((d, index) => (
          <div className="text-lg" key={index}>
            {d.toUpperCase()}
          </div>
        ))}
      </div>

      <>
        {currentCalendar.map((week, index) => (
          <div
            className="flex w-full h-full bg-white"
            key={format(week[index], "dd")}
          >
            {week.map((day) => (
              <div
                className={`flex-1 flex items-center justify-center normal:h-20 small:h-20 phone:h-10 normal:w-20 small:w-20 phone:w-10 ${
                  isBefore(day, startOfDay(new Date())) ||
                  !diasUnidade.includes(getTime(startOfDay(day)))
                    ? "cursor-default"
                    : "cursor-pointer"
                } ${
                  diasUnidade.includes(getTime(startOfDay(day))) &&
                  "bg-primary-green"
                }`}
                key={format(day, "dd")}
                onClick={() =>
                  !isBefore(day, startOfDay(new Date())) &&
                  diasUnidade.includes(getTime(startOfDay(day))) &&
                  onSelectDay(day)
                }
              >
                <div
                  className={`flex justify-center items-center h-10 w-10 rounded-full text-shade-black hover:bg-primary-green hover:text-white ${
                    isToday(day, new Date()) && "bg-slate-500"
                  } ${
                    (isBefore(day, startOfDay(new Date())) ||
                      !diasUnidade.includes(getTime(startOfDay(day)))) &&
                    "text-slate-300 pointer-events-none"
                  }`}
                >
                  {format(day, "dd")}
                </div>
              </div>
            ))}
          </div>
        ))}
      </>
    </div>
  );
};

export default Calendar;

import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../../context";

const PublicAuth = () => {
  const auth = useContext(AuthContext);

  return <>{auth?.isLoading === false && <Outlet />}</>;
};

export default PublicAuth;

import React, { useState, useEffect, useContext } from "react";
import { getHours } from "date-fns";
import { DatePickerSelector } from "../../../components";
import { AuthContext } from "../../../context";

const DataSelector = ({ pacienteRef, medicoRef, next }) => {
  const [diasUnidade, setDiasUnidade] = useState([]);
  const [intervaloMedico, setIntervaloMedico] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const auth = useContext(AuthContext);

  useEffect(() => {
    (() => {
      setIsLoading(true);
      const intervaloAtivoMedico = [];
      let diasAtivosUnidade;
      let horaInicial;
      let horaFinal;

      if (Object.keys(medicoRef).length !== 0) {
        diasAtivosUnidade = medicoRef.unidadeRef.diasAtivos;
        horaInicial = getHours(new Date(medicoRef.medicoInfo.horaInicial));
        horaFinal = getHours(new Date(medicoRef.medicoInfo.horaFinal));
      } else if (auth?.user && auth?.user?.tipo === "8181") {
        diasAtivosUnidade = auth?.user?.unidadeRef.diasAtivos;
        horaInicial = getHours(new Date(auth?.user?.medicoInfo.horaInicial));
        horaFinal = getHours(new Date(auth?.user?.medicoInfo.horaFinal));
      }

      while (horaInicial <= horaFinal) {
        intervaloAtivoMedico.push(
          `${horaInicial <= 9 ? `0${horaInicial}` : horaInicial}:00`
        );
        horaInicial += 1;
      }

      setDiasUnidade(diasAtivosUnidade);
      setIntervaloMedico(intervaloAtivoMedico);
      setIsLoading(false);
    })();
  }, [medicoRef, auth?.user]);

  return (
    <div className="max-w-3xl">
      {!isLoading && (
        <DatePickerSelector
          pacienteRef={pacienteRef}
          medicoRef={
            Object.keys(medicoRef).length !== 0
              ? medicoRef._id
              : auth?.user?._id
          }
          diasUnidade={diasUnidade}
          intervaloMedico={intervaloMedico}
          next={next}
        />
      )}
    </div>
  );
};

export default DataSelector;

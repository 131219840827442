import { configureStore } from "@reduxjs/toolkit";
import { neomedApi } from "./services/neomedApi";

export const store = configureStore({
  reducer: {
    [neomedApi.reducerPath]: neomedApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(neomedApi.middleware),
});

import React, { useState } from "react";
import { Modal, SectionListItem } from "../../../components";
import { PhoneIcon, DeviceMobileIcon } from "@heroicons/react/solid";
import PacienteMenu from "../PacienteMenu/PacienteMenu.jsx";
import PacienteStepperForm from "../PacienteStepperForm/PacienteStepperForm";
import PacienteDeleteForm from "../PacienteDeleteForm/PacienteDeleteForm";

const PacienteItem = ({ id, data, refetch }) => {
  const [pacienteEditModal, setPacienteEditModal] = useState(false);
  const [pacienteDeleteModal, setPacienteDeleteModal] = useState(false);

  const togglePacienteEditModal = () => {
    setPacienteEditModal((prevState) => !prevState);
  };

  const togglePacienteDeleteModal = () => {
    setPacienteDeleteModal((prevState) => !prevState);
  };

  return (
    <SectionListItem>
      <div className="flex items-center justify-between pt-4 pb-1 pl-4 pr-3">
        <div className="flex-col">
          <p className="text-base text-shade-black font-bold">{data.nome}</p>
        </div>

        <PacienteMenu
          showModalEditFunc={togglePacienteEditModal}
          pacienteRef={id}
          togglePacienteDeleteModal={togglePacienteDeleteModal}
        />

        <Modal
          openState={pacienteEditModal}
          closeFunc={togglePacienteEditModal}
        >
          <PacienteStepperForm
            togglePacienteEditModal={togglePacienteEditModal}
            pacienteData={{ id, ...data }}
            refetch={refetch}
          />
        </Modal>

        <Modal
          openState={pacienteDeleteModal}
          closeFunc={togglePacienteDeleteModal}
        >
          <PacienteDeleteForm
            togglePacienteDeleteModal={togglePacienteDeleteModal}
            usuarioId={id}
            refetch={refetch}
          />
        </Modal>
      </div>

      <div className="flex flex-col gap-4 p-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4">
            <PhoneIcon className="w-5 text-shade-green" />
            <p className="text-base text-shade-black font-medium">Telefone:</p>
          </div>
          <p className="text-sm text-primary-black">{data.telefone}</p>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4">
            <DeviceMobileIcon className="w-5 text-shade-green" />
            <p className="text-base text-shade-black font-medium">Celular:</p>
          </div>
          <p className="text-sm text-primary-black">{data.celular}</p>
        </div>
      </div>
    </SectionListItem>
  );
};

export default PacienteItem;

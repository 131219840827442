import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import StartPeer from "../../StartPeer";
import Meet from "../Meet";
import { AuthContext } from "../../context";

const MeetStarter = () => {
  const auth = useContext(AuthContext);
  const { id } = useParams();

  return (
    <StartPeer
      nome={auth?.user?.nome || "paciente"}
      medico={auth?.user?.tipo === "8181" ? true : false}
      agendamentoId={id}
    >
      <Meet agendamentoId={id} />
    </StartPeer>
  );
};

export default MeetStarter;

import { useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../context";

const NoAuth = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();

  return (
    <>
      {!auth?.user && auth?.isLoading === false ? (
        <Outlet />
      ) : (
        <Navigate to="/" state={{ from: location }} replace />
      )}
    </>
  );
};

export default NoAuth;

import React, { useState } from "react";
import { UserCircleIcon, ClockIcon, CashIcon } from "@heroicons/react/solid";
import { Modal, SectionListItem } from "../../../components";
import { getHours, getMinutes } from "date-fns";
import MedicoMenu from "../MedicoMenu/MedicoMenu.jsx";
import MedicoStepperForm from "../MedicoStepperForm/MedicoStepperForm";

const MedicoItem = ({ id, data, refetch }) => {
  const [medicoEditModal, setMedicoEditModal] = useState(false);

  const toggleMedicoEditModal = () => {
    setMedicoEditModal((prevState) => !prevState);
  };

  return (
    <SectionListItem>
      <div className="flex items-center justify-between pt-4 pb-1 pl-4 pr-3">
        <div className="flex gap-4">
          {data.medicoInfo?.imageUrl ? (
            <img
              className="w-10 h-10 rounded-full"
              src={data.medicoInfo.imageUrl}
              alt={data.medicoInfo.imageUrl}
            />
          ) : (
            <UserCircleIcon className="w-12 text-primary-black" />
          )}

          <p className="self-center text-base text-shade-black font-bold">
            {data.nome}
          </p>
        </div>

        <MedicoMenu showModalEditFunc={toggleMedicoEditModal} />

        <Modal openState={medicoEditModal} closeFunc={toggleMedicoEditModal}>
          <MedicoStepperForm
            toggleMedicoEditModal={toggleMedicoEditModal}
            medicoData={{ id, ...data }}
            refetch={refetch}
          />
        </Modal>
      </div>

      <div className="flex flex-col gap-4 p-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4">
            <ClockIcon className="w-5 text-shade-green" />
            <p className="text-base text-shade-black font-medium">
              Horário de atendimento:
            </p>
          </div>

          <p className="text-sm text-primary-black">
            {data.medicoInfo?.horaInicial && data.medicoInfo?.horaFinal
              ? `${
                  getHours(new Date(data.medicoInfo.horaInicial)) < 9
                    ? `0${getHours(new Date(data.medicoInfo.horaInicial))}`
                    : getHours(new Date(data.medicoInfo.horaInicial))
                }:${
                  getMinutes(new Date(data.medicoInfo.horaInicial)) < 9
                    ? `0${getMinutes(new Date(data.medicoInfo.horaInicial))}`
                    : getMinutes(new Date(data.medicoInfo.horaInicial))
                } | ${
                  getHours(new Date(data.medicoInfo.horaFinal)) < 9
                    ? `0${getHours(new Date(data.medicoInfo.horaFinal))}`
                    : getHours(new Date(data.medicoInfo.horaFinal))
                }:${
                  getMinutes(new Date(data.medicoInfo.horaFinal)) < 9
                    ? `0${getMinutes(new Date(data.medicoInfo.horaFinal))}`
                    : getMinutes(new Date(data.medicoInfo.horaFinal))
                }`
              : "Indefinido"}
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4">
            <CashIcon className="w-5 text-shade-green" />
            <p className="text-base text-shade-black font-medium">
              Preço de atendimento:
            </p>
          </div>
          <p className="text-sm text-primary-black">
            {data.medicoInfo?.preco
              ? `R$ ${data.medicoInfo?.preco}`
              : "Indefinido!"}
          </p>
        </div>
      </div>
    </SectionListItem>
  );
};

export default MedicoItem;

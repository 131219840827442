import React from "react";
import PacienteItem from "../PacienteItem/PacienteItem.jsx";
import { SectionList } from "../../../components";

const PacientesList = ({ items, refetch }) => {
  return (
    <SectionList>
      {items.map((paciente) => (
        <PacienteItem
          className="bg-white rounded-md drop-shadow-md"
          key={paciente?._id}
          id={paciente?._id}
          data={paciente}
          refetch={refetch}
        />
      ))}
    </SectionList>
  );
};

export default PacientesList;

import React from "react";
import ProntuarioItem from "../ProntuarioItem/ProntuarioItem";

const ProntuarioList = ({ prontuarioLista, changeStepHandler }) => {
  return (
    <ul className="grid grid-cols-1 gap-y-4">
      {prontuarioLista?.map((item, index) => (
        <ProntuarioItem
          key={item._id}
          changeStepHandler={changeStepHandler}
          index={index}
          createdAt={item.createdAt}
          nome={item.medicoRef.nome}
        />
      ))}
    </ul>
  );
};

export default ProntuarioList;

import React from "react";
import { SectionList } from "../../../components";
import AgendamentoItem from "../AgendamentoItem/AgendamentoItem";

const AgendamentoList = ({ items, refetch }) => {
  return (
    <SectionList>
      {items.map((agendamento) => (
        <AgendamentoItem
          key={agendamento._id}
          id={agendamento._id}
          data={agendamento}
          refetch={refetch}
        />
      ))}
    </SectionList>
  );
};

export default AgendamentoList;

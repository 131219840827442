import React from "react";

const StepperUI = ({ children, stepNumber, labels }) => {
  const steps = React.Children.toArray(children);
  const step = steps[stepNumber];

  return (
    <div className="flex flex-col gap-8">
      <div className="flex gap-4 justify-around">
        {steps.map((form, index) => (
          <div className="flex flex-col gap-2 items-center" key={index}>
            <div
              key={index}
              className={`w-10 h-10 flex items-center justify-center rounded-full text-primary-black ${
                stepNumber >= index ? "bg-primary-green" : "bg-slate-300"
              }`}
            >
              <span>{index + 1}</span>
            </div>

            <p className="text-sm text-primary-black">{labels[index]}</p>
          </div>
        ))}
      </div>

      {step}
    </div>
  );
};

export default StepperUI;

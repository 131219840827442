import React from "react";
import EspecialidadeItem from "../EspecialidadeItem/EspecialidadeItem.jsx";
import { SectionList } from "../../../components";

const EspecialidadesList = ({ items, refetch }) => {
  return (
    <SectionList>
      {items.map((especialidade) => (
        <EspecialidadeItem
          className="bg-white rounded-md drop-shadow-md"
          key={especialidade._id}
          id={especialidade._id}
          data={especialidade}
          refetch={refetch}
        />
      ))}
    </SectionList>
  );
};

export default EspecialidadesList;

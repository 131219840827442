import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  VolumeUpIcon,
  VideoCameraIcon,
  PhoneIcon,
  ClipboardListIcon,
} from "@heroicons/react/solid";
import { EditorState, convertToRaw } from "draft-js";
import ParticipantsList from "./ParticipantsList/ParticipantsList";
import { PeerContext, AuthContext } from "../../context";
import { disconnectUser } from "../../firebase/services/peerService";
import { Modal, TextEditorInput } from "../../components";
import {
  useGetAgendamentoByIdQuery,
  useUpdateAgendamentoMutation,
  useCreateFichaMutation,
} from "../../store/services/neomedApi";

const Meet = ({ agendamentoId }) => {
  const { participants, localStream, currentUser } = useContext(PeerContext);
  const auth = useContext(AuthContext);
  const participantsKey = Object.keys(participants);
  const [meetStatus, setMeetStatus] = useState("pendente");
  const [videoStatus, setVideoStatus] = useState(false);
  const [audioStatus, setAudioStatus] = useState(false);
  const [fichaModal, setFichaModal] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { data: response, isLoading } = useGetAgendamentoByIdQuery({
    agendamentoId,
  });
  const [updateAgendamento] = useUpdateAgendamentoMutation();
  const [createFicha] = useCreateFichaMutation();
  const navigate = useNavigate();

  // peer Functions
  const toogleVideoHandler = () => {
    localStream.getVideoTracks()[0].enabled =
      !localStream.getVideoTracks()[0].enabled;

    setVideoStatus((prevState) => !prevState);
  };

  const toogleAudioHandler = () => {
    localStream.getAudioTracks()[0].enabled =
      !localStream.getAudioTracks()[0].enabled;

    setAudioStatus((prevState) => !prevState);
  };

  const disconnectHandler = async () => {
    if (response?.data?.doc?.medicoRef?._id === auth?.user?._id) {
      const agendamentoObj = {
        andamento: "Encerrado",
      };

      await updateAgendamento({ agendamentoId, agendamentoObj });
    }

    await disconnectUser(agendamentoId, Object.keys(currentUser)[0]);

    localStream.getVideoTracks().forEach((track) => {
      track.stop();
    });

    localStream.getAudioTracks().forEach((track) => {
      track.stop();
    });

    participantsKey.forEach((key) => {
      if (!participants[key].currentUser) {
        participants[key].peerConection.close();
      }
    });

    alert("Voce Desconectou!");

    navigate("/");
  };

  const toggleFichaModal = () => {
    setFichaModal((prevState) => !prevState);
  };

  // pesquisar se paciente já possui prontuario
  const handleOnChange = () => {
    const contentState = editorState.getCurrentContent();

    const Obj = {
      medicoRef: response?.data?.doc?.medicoRef?._id,
      pacienteRef: response?.data?.doc?.pacienteRef?._id,
      orientacao: JSON.stringify(convertToRaw(contentState)),
    };

    const medicoData = {
      medicoNome: response?.data?.doc?.medicoRef?.nome,
      medicoCrm: response?.data?.doc?.medicoRef?.medicoInfo?.crm,
    };

    return { contentState, Obj, medicoData };
  };

  useEffect(() => {
    if (participantsKey.length === 1 && meetStatus === "iniciado") {
      alert("A reunião foi encerrada!");
    } else if (participantsKey.length === 2 && meetStatus === "pendente") {
      setMeetStatus("iniciado");
    }
  }, [meetStatus, participantsKey.length]);

  return (
    <section className="max-w-full mx-auto px-8 py-4">
      <ParticipantsList
        participantsKey={participantsKey}
        participants={participants}
        agendamentoId={agendamentoId}
      />

      <div className="absolute bottom-5 left-2/4 transform -translate-x-2/4 flex gap-5">
        <div
          className={`flex justify-center items-center w-10 h-10 rounded-full bg-neutral-400 cursor-pointer ${
            audioStatus
              ? "bg-red-900 text-white"
              : "bg-neutral-400 text-slate-900"
          }`}
          onClick={toogleAudioHandler}
        >
          <VolumeUpIcon className="w-5 text-slate-900" />
        </div>

        <div
          className={`flex justify-center items-center w-10 h-10 rounded-full bg-neutral-400 cursor-pointer ${
            videoStatus
              ? "bg-red-900 text-white"
              : "bg-neutral-400 text-slate-900"
          }`}
          onClick={toogleVideoHandler}
        >
          <VideoCameraIcon className="w-5 fill-current" />
        </div>

        <div
          className={`flex justify-center items-center w-10 h-10 rounded-full cursor-pointer bg-red-900 text-white`}
          onClick={disconnectHandler}
        >
          <PhoneIcon className="w-5 fill-current" />
        </div>

        {!isLoading &&
          response?.status === "success" &&
          response?.data?.doc?.medicoRef?._id === auth?.user?._id && (
            <div
              className="flex justify-center items-center w-10 h-10 rounded-full bg-neutral-400 text-slate-900 cursor-pointer"
              onClick={toggleFichaModal}
            >
              <ClipboardListIcon className="w-5 fill-current" />

              <Modal openState={fichaModal} closeFunc={toggleFichaModal}>
                <TextEditorInput
                  editorState={editorState}
                  setEditorState={setEditorState}
                  handleOnChange={handleOnChange}
                  readOnly={false}
                  pdfOnly={false}
                  apiAction={createFicha}
                  toggleFunc={toggleFichaModal}
                />
              </Modal>
            </div>
          )}
      </div>
    </section>
  );
};

export default Meet;

import React from "react";
import { Editor, RichUtils } from "draft-js";
import TextEditorHeader from "./TextEditorHeader/TextEditorHeader";
import { stateToHTML } from "draft-js-export-html";
import parse from "html-react-parser";
import ReactDomServer from "react-dom/server";
import jsPDF from "jspdf";
import { encode } from "base64-arraybuffer";
import { getToken } from "../../firebase/services/firebaseAuth";
import "draft-js/dist/Draft.css";
import "./TextEditor.css";

const TextEditorInput = ({
  editorState,
  setEditorState,
  handleOnChange,
  readOnly,
  pdfOnly,
  apiAction,
  toggleFunc,
  incrementFunc,
}) => {
  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return "handled";
    }

    return "not-handled";
  };

  const pdfProcess = (contentState, medicoData) => {
    const htmlString = stateToHTML(contentState, {
      inlineStyles: {
        BOLD: {
          attributes: {
            class: "bold",
          },
        },
        ITALIC: {
          attributes: {
            class: "italic",
          },
        },
        UNDERLINE: {
          attributes: {
            class: "underline",
          },
        },
      },

      blockStyleFn: (block) => {
        if (block.getType() === "unordered-list-item") {
          return {
            attributes: {
              class: "listaU",
            },
          };
        }

        if (block.getType() === "ordered-list-item") {
          return {
            attributes: {
              class: "listaO",
            },
          };
        }

        if (block.getType() === "header-one") {
          return {
            attributes: {
              class: "headerOne",
            },
          };
        }

        if (block.getType() === "header-two") {
          return {
            attributes: {
              class: "headerTwo",
            },
          };
        }

        if (block.getType() === "header-three") {
          return {
            attributes: {
              class: "headerThree",
            },
          };
        }
      },
    });

    const htmlJsx = parse(htmlString, {
      replace: (element) => {
        if (element?.attribs?.class === "listaU") {
          if (element?.firstChild?.attribs?.class === "underline") {
            if (element.firstChild?.children[0]?.attribs?.class === "italic") {
              if (
                element.firstChild?.children[0].firstChild?.attribs?.class ===
                "bold"
              ) {
                return (
                  <div>
                    {element.firstChild?.children?.map((child, index) => (
                      <div key={index}>
                        {child.children.map((innerChild, innerIndex) => (
                          <div key={innerIndex}>
                            {innerChild.children?.map(
                              (deepChild, deepIndex) => (
                                <div
                                  style={{
                                    display: "flex",
                                    fontSize: "4px",
                                    gap: "2px",
                                  }}
                                  key={deepIndex}
                                >
                                  <p>.</p>
                                  <p
                                    style={{
                                      whiteSpace: "nowrap",
                                      wordSpacing: "2px",
                                      textDecoration: "underline",
                                      fontStyle: "italic",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {deepChild.data}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                );
              }

              return (
                <div>
                  {element.firstChild?.children?.map((child, index) => (
                    <div key={index}>
                      {child.children.map((innerChild, innerIndex) => (
                        <div
                          style={{
                            display: "flex",
                            fontSize: "4px",
                            gap: "2px",
                          }}
                          key={innerIndex}
                        >
                          <p>.</p>
                          <p
                            style={{
                              whiteSpace: "nowrap",
                              wordSpacing: "2px",
                              textDecoration: "underline",
                              fontStyle: "italic",
                            }}
                          >
                            {innerChild.data}
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              );
            }

            if (element.firstChild?.children[0]?.attribs?.class === "bold") {
              return (
                <div>
                  {element.firstChild?.children?.map((child, index) => (
                    <div key={index}>
                      {child.children.map((innerChild, innerIndex) => (
                        <div
                          style={{
                            display: "flex",
                            fontSize: "4px",
                            gap: "2px",
                          }}
                          key={innerIndex}
                        >
                          <p>.</p>
                          <p
                            style={{
                              whiteSpace: "nowrap",
                              wordSpacing: "2px",
                              textDecoration: "underline",
                              fontWeight: "bold",
                            }}
                          >
                            {innerChild.data}
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              );
            }

            return (
              <div>
                {element.firstChild?.children?.map((child, index) => (
                  <div
                    style={{
                      display: "flex",
                      fontSize: "4px",
                      gap: "2px",
                    }}
                    key={index}
                  >
                    <p>.</p>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        wordSpacing: "2px",
                        textDecoration: "underline",
                      }}
                    >
                      {child.data}
                    </p>
                  </div>
                ))}
              </div>
            );
          }

          if (element?.firstChild?.attribs?.class === "italic") {
            if (element.firstChild?.children[0]?.attribs?.class === "bold") {
              return (
                <div>
                  {element.firstChild?.children?.map((child, index) => (
                    <div key={index}>
                      {child.children.map((innerChild, innerIndex) => (
                        <div
                          style={{
                            display: "flex",
                            fontSize: "4px",
                            gap: "2px",
                          }}
                          key={innerIndex}
                        >
                          <p>.</p>
                          <p
                            style={{
                              whiteSpace: "nowrap",
                              wordSpacing: "2px",
                              fontStyle: "italic",
                              fontWeight: "bold",
                            }}
                          >
                            {innerChild.data}
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              );
            }

            return (
              <div>
                {element.firstChild.children.map((child, index) => (
                  <div
                    style={{
                      display: "flex",
                      fontSize: "4px",
                      gap: "2px",
                    }}
                    key={index}
                  >
                    <p>.</p>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        wordSpacing: "2px",
                        fontStyle: "italic",
                      }}
                    >
                      {child.data}
                    </p>
                  </div>
                ))}
              </div>
            );
          }

          if (element?.firstChild?.attribs?.class === "bold") {
            return (
              <div>
                {element.firstChild.children.map((child, index) => (
                  <div
                    style={{
                      display: "flex",
                      fontSize: "4px",
                      gap: "2px",
                    }}
                    key={index}
                  >
                    <p>.</p>
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        wordSpacing: "2px",
                        fontWeight: "bold",
                      }}
                    >
                      {child.data}
                    </p>
                  </div>
                ))}
              </div>
            );
          }

          return (
            <div>
              {element.childNodes.map((child, index) => (
                <div
                  style={{
                    display: "flex",
                    fontSize: "4px",
                    gap: "2px",
                  }}
                  key={index}
                >
                  <p>.</p>
                  <p
                    style={{
                      whiteSpace: "nowrap",
                      wordSpacing: "2px",
                    }}
                  >
                    {child.data}
                  </p>
                </div>
              ))}
            </div>
          );
        }

        if (element?.attribs?.class === "headerOne") {
          if (element?.firstChild?.attribs?.class === "underline") {
            if (element.firstChild?.children[0]?.attribs?.class === "italic") {
              return (
                <div>
                  {element.firstChild?.children?.map((child, index) => (
                    <div key={index}>
                      {child.children.map((innerChild, innerIndex) => (
                        <h1
                          key={innerIndex}
                          style={{
                            whiteSpace: "nowrap",
                            wordSpacing: "2px",
                            fontSize: "10px",
                            gap: "2px",
                            textDecoration: "underline",
                            fontStyle: "italic",
                          }}
                        >
                          {innerChild.data}
                        </h1>
                      ))}
                    </div>
                  ))}
                </div>
              );
            }

            return (
              <div>
                {element.firstChild.children.map((child, index) => (
                  <h1
                    key={index}
                    style={{
                      whiteSpace: "nowrap",
                      wordSpacing: "2px",
                      fontSize: "10px",
                      gap: "2px",
                      textDecoration: "underline",
                    }}
                  >
                    {child.data}
                  </h1>
                ))}
              </div>
            );
          }

          if (element?.firstChild?.attribs?.class === "italic") {
            return (
              <div>
                {element.firstChild.children.map((child, index) => (
                  <h1
                    key={index}
                    style={{
                      whiteSpace: "nowrap",
                      wordSpacing: "2px",
                      fontSize: "10px",
                      gap: "2px",
                      fontStyle: "italic",
                    }}
                  >
                    {child.data}
                  </h1>
                ))}
              </div>
            );
          }

          return (
            <div>
              {element.childNodes.map((child, index) => (
                <h1
                  style={{
                    whiteSpace: "nowrap",
                    wordSpacing: "2px",
                    fontSize: "10px",
                    gap: "2px",
                  }}
                  key={index}
                >
                  {child.data}
                </h1>
              ))}
            </div>
          );
        }

        if (element?.attribs?.class === "headerTwo") {
          if (element?.firstChild?.attribs?.class === "underline") {
            if (element.firstChild?.children[0]?.attribs?.class === "italic") {
              return (
                <div>
                  {element.firstChild?.children?.map((child, index) => (
                    <div key={index}>
                      {child.children.map((innerChild, innerIndex) => (
                        <h2
                          key={innerIndex}
                          style={{
                            whiteSpace: "nowrap",
                            wordSpacing: "2px",
                            fontSize: "8px",
                            gap: "2px",
                            textDecoration: "underline",
                            fontStyle: "italic",
                          }}
                        >
                          {innerChild.data}
                        </h2>
                      ))}
                    </div>
                  ))}
                </div>
              );
            }

            return (
              <div>
                {element.firstChild.children.map((child, index) => (
                  <h2
                    key={index}
                    style={{
                      whiteSpace: "nowrap",
                      wordSpacing: "2px",
                      fontSize: "8px",
                      gap: "2px",
                      textDecoration: "underline",
                    }}
                  >
                    {child.data}
                  </h2>
                ))}
              </div>
            );
          }

          if (element?.firstChild?.attribs?.class === "italic") {
            return (
              <div>
                {element.firstChild.children.map((child, index) => (
                  <h2
                    key={index}
                    style={{
                      whiteSpace: "nowrap",
                      wordSpacing: "2px",
                      fontSize: "8px",
                      gap: "2px",
                      fontStyle: "italic",
                    }}
                  >
                    {child.data}
                  </h2>
                ))}
              </div>
            );
          }

          return (
            <div>
              {element.childNodes.map((child, index) => (
                <h2
                  style={{
                    whiteSpace: "nowrap",
                    wordSpacing: "2px",
                    fontSize: "8px",
                    gap: "2px",
                  }}
                  key={index}
                >
                  {child.data}
                </h2>
              ))}
            </div>
          );
        }

        if (element?.attribs?.class === "headerThree") {
          if (element?.firstChild?.attribs?.class === "underline") {
            if (element.firstChild?.children[0]?.attribs?.class === "italic") {
              return (
                <div>
                  {element.firstChild?.children?.map((child, index) => (
                    <div key={index}>
                      {child.children.map((innerChild, innerIndex) => (
                        <h3
                          key={innerIndex}
                          style={{
                            whiteSpace: "nowrap",
                            wordSpacing: "2px",
                            fontSize: "6px",
                            gap: "2px",
                            textDecoration: "underline",
                            fontStyle: "italic",
                          }}
                        >
                          {innerChild.data}
                        </h3>
                      ))}
                    </div>
                  ))}
                </div>
              );
            }

            return (
              <div>
                {element.firstChild.children.map((child, index) => (
                  <h3
                    key={index}
                    style={{
                      whiteSpace: "nowrap",
                      wordSpacing: "2px",
                      fontSize: "6px",
                      gap: "2px",
                      textDecoration: "underline",
                    }}
                  >
                    {child.data}
                  </h3>
                ))}
              </div>
            );
          }

          if (element?.firstChild?.attribs?.class === "italic") {
            return (
              <div>
                {element.firstChild.children.map((child, index) => (
                  <h3
                    key={index}
                    style={{
                      whiteSpace: "nowrap",
                      wordSpacing: "2px",
                      fontSize: "6px",
                      gap: "2px",
                      fontStyle: "italic",
                    }}
                  >
                    {child.data}
                  </h3>
                ))}
              </div>
            );
          }

          return (
            <div>
              {element.childNodes.map((child, index) => (
                <h3
                  style={{
                    whiteSpace: "nowrap",
                    wordSpacing: "2px",
                    fontSize: "6px",
                    gap: "2px",
                  }}
                  key={index}
                >
                  {child.data}
                </h3>
              ))}
            </div>
          );
        }

        if (element?.attribs?.class === "bold") {
          return (
            <div>
              {element.childNodes.map((child, index) => (
                <p
                  style={{
                    whiteSpace: "nowrap",
                    wordSpacing: "2px",
                    fontSize: "4px",
                    gap: "2px",
                    fontWeight: "bold",
                  }}
                  key={index}
                >
                  {child.data}
                </p>
              ))}
            </div>
          );
        }

        if (element?.attribs?.class === "italic") {
          if (element?.firstChild?.attribs?.class === "bold") {
            return (
              <div>
                {element.firstChild.children.map((child, index) => (
                  <p
                    key={index}
                    style={{
                      whiteSpace: "nowrap",
                      wordSpacing: "2px",
                      fontSize: "4px",
                      gap: "2px",
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }}
                  >
                    {child.data}
                  </p>
                ))}
              </div>
            );
          }

          return (
            <div>
              {element.childNodes.map((child, index) => (
                <p
                  style={{
                    whiteSpace: "nowrap",
                    wordSpacing: "2px",
                    fontSize: "4px",
                    gap: "2px",
                    fontStyle: "italic",
                  }}
                  key={index}
                >
                  {child.data}
                </p>
              ))}
            </div>
          );
        }

        if (element?.attribs?.class === "underline") {
          if (element?.firstChild?.attribs?.class === "italic") {
            if (element.firstChild?.children[0]?.attribs?.class === "bold") {
              return (
                <div>
                  {element.firstChild?.children?.map((child, index) => (
                    <div key={index}>
                      {child.children.map((innerChild, innerIndex) => (
                        <p
                          key={innerIndex}
                          style={{
                            whiteSpace: "nowrap",
                            wordSpacing: "2px",
                            fontSize: "4px",
                            gap: "2px",
                            fontStyle: "italic",
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          {innerChild.data}
                        </p>
                      ))}
                    </div>
                  ))}
                </div>
              );
            }

            return (
              <div>
                {element.firstChild.children.map((child, index) => (
                  <p
                    key={index}
                    style={{
                      whiteSpace: "nowrap",
                      wordSpacing: "2px",
                      fontSize: "4px",
                      gap: "2px",
                      fontStyle: "italic",
                      textDecoration: "underline",
                    }}
                  >
                    {child.data}
                  </p>
                ))}
              </div>
            );
          }

          if (element?.firstChild?.attribs?.class === "bold") {
            return (
              <div>
                {element.firstChild.children.map((child, index) => (
                  <p
                    key={index}
                    style={{
                      whiteSpace: "nowrap",
                      wordSpacing: "2px",
                      fontSize: "4px",
                      gap: "2px",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    {child.data}
                  </p>
                ))}
              </div>
            );
          }

          return (
            <div>
              {element.childNodes.map((child, index) => (
                <p
                  style={{
                    whiteSpace: "nowrap",
                    wordSpacing: "2px",
                    fontSize: "4px",
                    gap: "2px",
                    textDecoration: "underline",
                  }}
                  key={index}
                >
                  {child.data}
                </p>
              ))}
            </div>
          );
        }

        if (!element?.childNodes) {
          return (
            <div>
              {
                <p
                  style={{
                    whiteSpace: "nowrap",
                    wordSpacing: "2px",
                    fontSize: "4px",
                    gap: "2px",
                  }}
                >
                  {element.data}
                </p>
              }
            </div>
          );
        }
      },
    });

    const jsxWrapper = (
      <div style={{ marginTop: "30px", marginLeft: "10px" }}>{htmlJsx}</div>
    );

    const jsxFinal = ReactDomServer.renderToStaticMarkup(jsxWrapper);

    const doc = new jsPDF();

    doc.html(jsxFinal, {
      callback: async function (pdf) {
        const token = await getToken();

        const response = await fetch(
          `${
            process.env.NODE_ENV === "development"
              ? process.env.REACT_APP_DEV_API_URL
              : process.env.REACT_APP_PROD_API_URL
          }/pdf`,
          {
            mode: "cors",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              pdf: encode(pdf.output("arraybuffer")),
              ...medicoData,
            }),
          }
        );

        if (response.ok) {
          const responseData = await response.json();
          var html =
            "<html>" +
            "<style>html, body { padding: 0; margin: 0; } iframe { width: 100%; height: 100%; border: 0;}  </style>" +
            "<body>" +
            `<iframe src="data:application/pdf;base64,${responseData.base64Data}"></iframe>` +
            "</body></html>";

          var x = window.open();
          x.document.open();
          x.document.write(html);
          x.document.close();
        }
      },
    });
  };

  const saveHandler = async () => {
    const { contentState, Obj, medicoData } = handleOnChange();

    const { data: response } = await apiAction({ Obj });

    if (response?.status === "success" && incrementFunc) {
      incrementFunc((prevState) => {
        if (!prevState) {
          return [response.data.doc];
        }

        return [response.data.doc, ...prevState];
      });
    }

    if (response?.status === "success") {
      pdfProcess(contentState, medicoData);

      toggleFunc();
    }
  };

  const pdfHandler = async () => {
    const { contentState, medicoData } = handleOnChange();

    pdfProcess(contentState, medicoData);

    toggleFunc();
  };

  return (
    <div
      style={{ width: "600px", height: "500px" }}
      className="flex flex-col gap-4"
    >
      <TextEditorHeader
        editorState={editorState}
        setEditorState={setEditorState}
      />

      <div className="border-2 border-slate-400">
        <Editor
          editorState={editorState}
          onChange={setEditorState}
          handleKeyCommand={handleKeyCommand}
          readOnly={readOnly ? true : false}
        />
      </div>

      {readOnly === false ? (
        <button
          className="bg-primary-red p-4 rounded-md self-center"
          onClick={saveHandler}
        >
          <span className="text-white">Salvar</span>
        </button>
      ) : null}

      {pdfOnly === true ? (
        <button
          className="bg-primary-red p-4 rounded-md self-center"
          onClick={pdfHandler}
        >
          <span className="text-white">Baixar PDF</span>
        </button>
      ) : null}
    </div>
  );
};

export default TextEditorInput;

import React from "react";
import { useDeleteEspecialidadeMutation } from "../../../store/services/neomedApi";

const EspecialidadeDeleteForm = ({
  toggleEspecialidadeDeleteModal,
  especialidadeId,
  refetch,
}) => {
  const [deleteEspecialidade, { error }] = useDeleteEspecialidadeMutation();

  const deleteEspecialidadeHandler = async () => {
    await deleteEspecialidade({ especialidadeId });

    if (!error) {
      refetch();
    }

    toggleEspecialidadeDeleteModal();
  };

  return (
    <div className="flex flex-col gap-9 p-4">
      <p className="self-center">Tem Certeza ?</p>
      <button
        type="button"
        className="self-center bg-primary-red rounded-md p-4 text-sm text-primary-black font-medium tracking-widest"
        onClick={deleteEspecialidadeHandler}
      >
        REMOVER
      </button>
    </div>
  );
};

export default EspecialidadeDeleteForm;

import React, { useState, useRef, useEffect, useContext } from "react";
import { UserCircleIcon } from "@heroicons/react/solid";
import { v4 as uuidv4 } from "uuid";
import { ButtonInput } from "../../../components";
import {
  uploadFile,
  deleteFile,
} from "../../../firebase/services/firebaseStorage";
import { useUpdateUsuarioMutation } from "../../../store/services/neomedApi";
import { AuthContext } from "../../../context";

const ImageUploadPreview = ({
  basePath,
  setImageUrl,
  toggleAndDeleteImageModal,
  toggleImageModal,
}) => {
  const [uploadProgress, setUploadProgress] = useState(-1);
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [uploadImageUrl, setUploadImageUrl] = useState("");
  const fileInputRef = useRef();
  const [updateUser] = useUpdateUsuarioMutation();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth?.user?.imageUrl) {
      setCurrentImageUrl(auth?.user?.imageUrl);
    } else {
      setUploadProgress(-1);
      setCurrentImageUrl("");
      fileInputRef.current.value = null;
    }
  }, [auth?.user?.imageUrl]);

  const fileChangedHandler = async (event) => {
    const files = event.target.files;
    const file = files[0];

    if (!file) {
      alert("Deu ruim!, tenta de novo!");
      return;
    }

    const generatedFileId = uuidv4();

    try {
      const downloadUrl = await uploadFile(
        file,
        `${basePath}/${generatedFileId}`,
        setUploadProgress
      );

      setUploadImageUrl(downloadUrl);
      setImageUrl(downloadUrl);
    } catch (error) {
      setUploadProgress(-1);
      fileInputRef.current.value = null;
      alert(error.message);
      throw error;
    }
  };

  const cancelImageHandler = async () => {
    await deleteFile(uploadImageUrl);
    fileInputRef.current.value = null;
    setUploadImageUrl("");
    setUploadProgress(-1);
    await toggleAndDeleteImageModal();
  };

  const submitImageHandler = async () => {
    const usuarioObj = { imageUrl: uploadImageUrl };

    const { data: response } = await updateUser({
      usuarioId: auth?.user?._id,
      usuarioObj,
    });

    if (response?.status === "success") {
      auth?.setUser((prevState) => {
        const user = { ...prevState };

        user.imageUrl = response.data?.doc?.imageUrl;

        return user;
      });
    }

    toggleImageModal();
  };

  return (
    <div className="flex flex-col gap-8">
      {/** Preview */}
      <div className="w-32 self-center">
        {uploadImageUrl ? (
          <img className="w-full" src={uploadImageUrl} alt={uploadImageUrl} />
        ) : currentImageUrl && !uploadImageUrl ? (
          <img className="w-full" src={currentImageUrl} alt={currentImageUrl} />
        ) : (
          <UserCircleIcon className="w-full text-primary-black" />
        )}
      </div>

      {/** Input */}
      <div className="flex justify-center gap-4">
        {!uploadImageUrl && (
          <>
            <button
              className={`flex justify-center items-center gap-2 bg-primary-green px-4 py-2 text-primary-black border-none rounded-md shadow-md transition-all duration-200 ease-out hover:bg-shade-green ${
                uploadProgress > -1 && "hidden"
              }`}
              title="Upload Imagem"
              onClick={() => fileInputRef.current.click()}
            >
              <span className="text-base font-bold">Upload</span>
            </button>

            <input
              className="hidden"
              type={"file"}
              ref={fileInputRef}
              onChange={fileChangedHandler}
            />
          </>
        )}

        {uploadImageUrl && (
          <ButtonInput type={"button"} onClick={submitImageHandler}>
            <span className="text-base font-bold">Aceitar</span>
          </ButtonInput>
        )}

        {uploadImageUrl && (
          <ButtonInput type={"button"} onClick={cancelImageHandler}>
            <span className="text-base font-bold">Cancelar</span>
          </ButtonInput>
        )}
      </div>

      {/** Progress */}
      {!uploadImageUrl && uploadProgress > -1 ? (
        <div className="flex flex-col items-center">
          <label htmlFor="file">Carregando: {uploadProgress}%</label>
          <div className="text-shade-green">
            <div className="border-t-transparent w-16 h-16 border-4 border-primary-green border-solid rounded-full animate-spin" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ImageUploadPreview;

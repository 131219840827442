import React from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  DotsHorizontalIcon,
  PlusIcon,
  ClipboardListIcon,
} from "@heroicons/react/solid";

const ProntuarioMenu = ({ historicoFunc, fichaForm }) => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button>
        <DotsHorizontalIcon className="w-5 text-shade-green hover:text-primary-green transition-all duration-50 ease-out" />
      </Menu.Button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="flex flex-col bg-white shadow-md rounded-lg w-56 px-1 py-1 absolute right-0 z-10">
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                title="Editar"
                onClick={() => {
                  fichaForm();
                }}
                className={`${
                  active
                    ? "bg-primary-green text-gray-50"
                    : "text-primary-black"
                } flex items-center gap-2 rounded-md px-1 py-1 `}
              >
                <PlusIcon className="w-5 fill-current" />
                <span>Novo</span>
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                title="Editar"
                onClick={() => {
                  historicoFunc();
                }}
                className={`${
                  active
                    ? "bg-primary-green text-gray-50"
                    : "text-primary-black"
                } flex items-center gap-2 rounded-md px-1 py-1 `}
              >
                <ClipboardListIcon className="w-5 fill-current" />
                <span>Historico</span>
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProntuarioMenu;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken } from "../../firebase/services/firebaseAuth";

export const neomedApi = createApi({
  reducerPath: "neomedApi",
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEV_API_URL
        : process.env.REACT_APP_PROD_API_URL,
    mode: "cors",
    prepareHeaders: async (headers) => {
      const token = await getToken();

      headers.set("authorization", `Bearer ${token}`);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllUnidades: builder.query({
      query: ({ paramsObj }) => ({
        url: "/unidades",
        params: paramsObj,
      }),
    }),
    getUnidadeById: builder.query({
      query: ({ unidadeId }) => ({
        url: `/unidades/${unidadeId}`,
      }),
    }),
    createUnidade: builder.mutation({
      query: ({ unidadeObj }) => ({
        url: "/unidades",
        method: "POST",
        body: unidadeObj,
      }),
    }),
    updateUnidade: builder.mutation({
      query: ({ unidadeId, unidadeObj }) => ({
        url: `/unidades/${unidadeId}`,
        method: "PATCH",
        body: unidadeObj,
      }),
    }),
    deleteUnidade: builder.mutation({
      query: ({ unidadeId }) => ({
        url: `/unidades/${unidadeId}`,
        method: "DELETE",
      }),
    }),
    getAllEspecialidades: builder.query({
      query: ({ paramsObj }) => ({
        url: "/especialidades",
        params: paramsObj,
      }),
    }),
    getEspecialidadeById: builder.query({
      query: ({ especialidadeId }) => ({
        url: `/especialidades/${especialidadeId}`,
      }),
    }),
    createEspecialidade: builder.mutation({
      query: ({ especialidadeObj }) => ({
        url: "/especialidades",
        method: "POST",
        body: especialidadeObj,
      }),
    }),
    updateEspecialidade: builder.mutation({
      query: ({ especialidadeId, especialidadeObj }) => ({
        url: `/especialidades/${especialidadeId}`,
        method: "PATCH",
        body: especialidadeObj,
      }),
    }),
    deleteEspecialidade: builder.mutation({
      query: ({ especialidadeId }) => ({
        url: `/especialidades/${especialidadeId}`,
        method: "DELETE",
      }),
    }),
    getAllUsuarios: builder.query({
      query: ({ paramsObj }) => ({
        url: "/usuarios",
        params: paramsObj,
      }),
    }),
    getUsuarioById: builder.query({
      query: ({ usuarioId }) => ({
        url: `/usuarios/${usuarioId}`,
      }),
    }),
    createUsuarioPaciente: builder.mutation({
      query: ({ usuarioObj }) => ({
        url: "/usuarios/signup/paciente",
        method: "POST",
        body: usuarioObj,
      }),
    }),
    createUsuarioAdmin: builder.mutation({
      query: ({ unidadeId, usuarioObj }) => ({
        url: `/unidades/${unidadeId}/usuarios/signup/funcionario`,
        method: "POST",
        body: usuarioObj,
      }),
    }),
    createUsuarioMedico: builder.mutation({
      query: ({ unidadeId, especialidadeId, usuarioObj }) => ({
        url: `/unidades/${unidadeId}/especialidades/${especialidadeId}/usuarios/signup/medico`,
        method: "POST",
        body: usuarioObj,
      }),
    }),
    updateUsuario: builder.mutation({
      query: ({ usuarioId, usuarioObj }) => ({
        url: `/usuarios/${usuarioId}`,
        method: "PATCH",
        body: usuarioObj,
      }),
    }),
    deleteUsuario: builder.mutation({
      query: ({ usuarioId }) => ({
        url: `/usuarios/${usuarioId}`,
        method: "DELETE",
      }),
    }),
    getAllAgendamentos: builder.query({
      query: ({ paramsObj, usuarioId }) => {
        if (!usuarioId) {
          return {
            url: "/agendamentos",
            params: paramsObj,
          };
        } else {
          return {
            url: `/usuarios/${usuarioId}/agendamentos`,
            params: paramsObj,
          };
        }
      },
    }),
    getAgendamentoById: builder.query({
      query: ({ agendamentoId }) => ({
        url: `/agendamentos/${agendamentoId}`,
      }),
    }),
    createAgendamento: builder.mutation({
      query: ({ agendamentoObj }) => ({
        url: "/agendamentos",
        method: "POST",
        body: agendamentoObj,
      }),
    }),
    updateAgendamento: builder.mutation({
      query: ({ agendamentoId, agendamentoObj }) => ({
        url: `/agendamentos/${agendamentoId}`,
        method: "PATCH",
        body: agendamentoObj,
      }),
    }),
    deleteAgendamento: builder.mutation({
      query: ({ agendamentoId }) => ({
        url: `/agendamentos/${agendamentoId}`,
        method: "DELETE",
      }),
    }),
    getAllFichas: builder.query({
      query: ({ paramsObj }) => ({
        url: "/fichas",
        params: paramsObj,
      }),
    }),
    getFichaById: builder.query({
      query: ({ Id }) => ({
        url: `/fichas/${Id}`,
      }),
    }),
    createFicha: builder.mutation({
      query: ({ Obj }) => ({
        url: "/fichas",
        method: "POST",
        body: Obj,
      }),
    }),
    updateFicha: builder.mutation({
      query: ({ Id, Obj }) => ({
        url: `/fichas/${Id}`,
        method: "PATCH",
        body: Obj,
      }),
    }),
    deleteFicha: builder.mutation({
      query: ({ Id }) => ({
        url: `/fichas/${Id}`,
        method: "DELETE",
      }),
    }),
    getAllAtestados: builder.query({
      query: ({ paramsObj }) => ({
        url: "/atestados",
        params: paramsObj,
      }),
    }),
    getAtestadoById: builder.query({
      query: ({ Id }) => ({
        url: `/atestados/${Id}`,
      }),
    }),
    createAtestado: builder.mutation({
      query: ({ Obj }) => ({
        url: "/atestados",
        method: "POST",
        body: Obj,
      }),
    }),
    updateAtestado: builder.mutation({
      query: ({ Id, Obj }) => ({
        url: `/atestados/${Id}`,
        method: "PATCH",
        body: Obj,
      }),
    }),
    deleteAtestado: builder.mutation({
      query: ({ Id }) => ({
        url: `/atestados/${Id}`,
        method: "DELETE",
      }),
    }),
    getAllReceitas: builder.query({
      query: ({ paramsObj }) => ({
        url: "/receitas",
        params: paramsObj,
      }),
    }),
    getReceitaById: builder.query({
      query: ({ Id }) => ({
        url: `/receitas/${Id}`,
      }),
    }),
    createReceita: builder.mutation({
      query: ({ Obj }) => ({
        url: "/receitas",
        method: "POST",
        body: Obj,
      }),
    }),
    updateReceita: builder.mutation({
      query: ({ Id, Obj }) => ({
        url: `/receitas/${Id}`,
        method: "PATCH",
        body: Obj,
      }),
    }),
    deleteReceita: builder.mutation({
      query: ({ Id }) => ({
        url: `/receitas/${Id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllUnidadesQuery,
  useGetUnidadeByIdQuery,
  useCreateUnidadeMutation,
  useUpdateUnidadeMutation,
  useDeleteUnidadeMutation,
  useGetAllEspecialidadesQuery,
  useGetEspecialidadeByIdQuery,
  useCreateEspecialidadeMutation,
  useUpdateEspecialidadeMutation,
  useDeleteEspecialidadeMutation,
  useGetAllUsuariosQuery,
  useGetUsuarioByIdQuery,
  useCreateUsuarioPacienteMutation,
  useCreateUsuarioAdminMutation,
  useCreateUsuarioMedicoMutation,
  useUpdateUsuarioMutation,
  useDeleteUsuarioMutation,
  useGetAllAgendamentosQuery,
  useGetAgendamentoByIdQuery,
  useCreateAgendamentoMutation,
  useUpdateAgendamentoMutation,
  useDeleteAgendamentoMutation,
  useGetAllFichasQuery,
  useGetFichaByIdQuery,
  useCreateFichaMutation,
  useUpdateFichaMutation,
  useDeleteFichaMutation,
  useGetAllAtestadosQuery,
  useGetAtestadoByIdQuery,
  useCreateAtestadoMutation,
  useUpdateAtestadoMutation,
  useDeleteAtestadoMutation,
  useGetAllReceitasQuery,
  useGetReceitaByIdQuery,
  useCreateReceitaMutation,
  useUpdateReceitaMutation,
  useDeleteReceitaMutation,
} = neomedApi;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextFieldInput, ButtonInput } from "../../../components";
import UnidadeCalendarSelector from "../UnidadeCalendarSelector/UnidadeCalendarSelector";

const schema = Yup.object().shape({
  nome: Yup.string().required("Campo Obrigatório"),
  email: Yup.string().email("Formato Errado!").required("Campo Obrigatório"),
});

const UnidadeCreateForm = ({ next, unidadeData }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      nome: unidadeData ? unidadeData.nome : "",
      email: unidadeData ? unidadeData.email : "",
    },
    resolver: yupResolver(schema),
  });

  const [activeDays, setActiveDays] = useState(
    unidadeData ? unidadeData.diasAtivos : []
  );

  const nextStepHandler = (data) => {
    next({ ...data, diasAtivos: activeDays });
  };

  return (
    <form
      onSubmit={handleSubmit(nextStepHandler)}
      className="grid normal:grid-cols-2 small:grid-cols-2 phone:grid-cols-1 gap-9 p-4"
    >
      <TextFieldInput
        name={"nome"}
        control={control}
        label="Nome"
        type={"text"}
        error={errors?.nome}
      />

      <TextFieldInput
        name={"email"}
        control={control}
        label="Email"
        type={"text"}
        error={errors?.email}
      />

      <UnidadeCalendarSelector
        activeDays={activeDays}
        setActiveDays={setActiveDays}
      />

      <ButtonInput styles={"col-span-full justify-self-center"} type={"submit"}>
        Proximo
      </ButtonInput>
    </form>
  );
};

export default UnidadeCreateForm;

import React, { useState } from "react";
import ProntuarioList from "./ProntuarioList/ProntuarioList";
import ProntuarioHistoryItem from "./ProntuarioHistoryItem/ProntuarioHistoryItem";

const ProntuarioStepper = ({ prontuarioLista, toggleFunc }) => {
  const [step, setStep] = useState(0);
  const [idx, setIdx] = useState(null);

  const changeStepHandler = (index) => {
    setStep((prevState) => prevState + 1);
    setIdx(index);
  };

  if (step > 0) {
    return (
      <ProntuarioHistoryItem
        prontuarioObj={prontuarioLista[idx]}
        toggleFunc={toggleFunc}
      />
    );
  }

  if (!prontuarioLista) {
    return (
      <p className="text-2xl text-shade-black font-bold">Histórico Vazio</p>
    );
  }

  return (
    <ProntuarioList
      prontuarioLista={prontuarioLista}
      changeStepHandler={changeStepHandler}
    />
  );
};

export default ProntuarioStepper;

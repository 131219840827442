import React, { useState, useEffect } from "react";
import {
  setHours,
  setMinutes,
  setSeconds,
  parseISO,
  getHours,
  getMinutes,
} from "date-fns";
import {
  useGetAllAgendamentosQuery,
  useCreateAgendamentoMutation,
  useUpdateAgendamentoMutation,
} from "../../../store/services/neomedApi";

const HourSelector = ({
  selectedDate,
  dateConstraints,
  usersData,
  toggleAgendamentoCreateModal,
  toggleAgendamentoEditModal,
  agendamentoData,
  refetch,
}) => {
  const { data: response, refetch: refetchHours } = useGetAllAgendamentosQuery({
    paramsObj: {
      start: dateConstraints.initDate,
      end: dateConstraints.endDate,
    },
    usuarioId: usersData.medicoRef,
  });
  const [createAgendamento] = useCreateAgendamentoMutation();
  const [updateAgendamento] = useUpdateAgendamentoMutation();
  const [isLoading, setIsLoading] = useState(true);
  const [reservedSlots, setReservedSlots] = useState([]);

  useEffect(() => {
    const array = [];

    response?.data?.docs.forEach((agenda) => {
      const data = agenda.data;
      const hour = getHours(new Date(data));
      const minutes = getMinutes(new Date(data));

      array.push(
        `${hour < 10 ? `0${hour}` : hour}:${
          minutes < 10 ? `0${minutes}` : minutes
        }`
      );
    });

    setReservedSlots(array);
    setIsLoading(false);
  }, [response]);

  const createAgendamentoHandler = async (hour) => {
    const agendamentoObj = {
      medicoRef: usersData.medicoRef,
      pacienteRef: usersData.pacienteRef,
      data: setHours(
        setMinutes(
          setSeconds(parseISO(selectedDate), 0),
          Number(hour.split(":")[1])
        ),
        Number(hour.split(":")[0])
      ).getTime(),
    };

    const { data: res } = await createAgendamento({ agendamentoObj });

    if (res?.status === "success") {
      refetchHours();
      refetch();
    }

    toggleAgendamentoCreateModal();
  };

  const updateAgendamentoHandler = async (hour) => {
    const agendamentoObj = {
      medicoRef: usersData.medicoRef,
      pacienteRef: usersData.pacienteRef,
      data: setHours(
        setMinutes(
          setSeconds(parseISO(selectedDate), 0),
          Number(hour.split(":")[1])
        ),
        Number(hour.split(":")[0])
      ).getTime(),
      andamento: "Pendente",
    };

    const { data: res } = await updateAgendamento({
      agendamentoId: agendamentoData._id,
      agendamentoObj,
    });

    if (res?.status === "success") {
      refetchHours();
      refetch();
    }

    toggleAgendamentoEditModal();
  };

  return (
    <div className="grid grid-cols-4 gap-4 p-1">
      {!isLoading &&
        dateConstraints.hours.map((hour, index) => (
          <div
            className={`flex justify-center px-4 py-2 rounded-md font-semibold cursor-pointer ${
              reservedSlots.includes(hour)
                ? "bg-red-300 cursor-default pointer-events-none"
                : "bg-slate-300"
            }`}
            key={index}
            onClick={() => {
              agendamentoData
                ? updateAgendamentoHandler(hour)
                : createAgendamentoHandler(hour);
            }}
          >
            {hour}
          </div>
        ))}
    </div>
  );
};

export default HourSelector;

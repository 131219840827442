import React, { useState } from "react";
import { CalendarIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import { format, endOfDay, startOfDay, getTime, parseISO } from "date-fns";
import { Calendar } from "../../../components";

const PacienteCalendarSelector = ({
  activeDays,
  setActiveDays,
  nascimento,
}) => {
  const [value, setValue] = useState(
    nascimento
      ? format(endOfDay(new Date(nascimento)), "yyyy-MM-dd'T'HH:mm:ss")
      : format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss")
  );

  const [displayValue, setDisplayValue] = useState(
    nascimento
      ? format(endOfDay(new Date(nascimento)), "yyyy-MM-dd'T'HH:mm:ss")
      : format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss")
  );

  const selectDayHandler = (day) => {
    const array = [];

    array.push(getTime(startOfDay(day)));

    setActiveDays(array);

    setDisplayValue(format(day, "yyyy-MM-dd'T'HH:mm:ss"));
  };

  return (
    <Menu as={"div"} className="relative">
      <Menu.Button className={"w-full"}>
        <div className="relative h-14 border rounded-md bg-white text-primary-black border-slate-900 border-opacity-25">
          <input
            className="outline-none w-full h-full rounded-md bg-transparent text-sm transition-all duration-200 ease-in-out px-2 py-8"
            type={"text"}
            readOnly
            id="calendar"
            value={format(parseISO(displayValue), "yyyy-MM-dd")}
          />

          <CalendarIcon className="w-5 text-primary-black absolute top-2/4 right-2 transform -translate-y-2/4 cursor-pointer" />

          <label
            className="absolute text-xs top-3 left-0 transform -translate-y-2/4 text-primary-black text-opacity-50 p-2"
            htmlFor="calendar"
          >
            Nascimento
          </label>
        </div>
      </Menu.Button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className={"absolute right-0 z-10"}>
          <Menu.Item>
            {() => (
              <Calendar
                value={value}
                onChange={setValue}
                selectDayHandler={selectDayHandler}
                activeDays={activeDays}
                allowPast
              />
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default PacienteCalendarSelector;

import React from "react";
import MedicoItem from "../MedicoItem/MedicoItem.jsx";
import { SectionList } from "../../../components";

const MedicosList = ({ items, refetch }) => {
  return (
    <SectionList>
      {items.map((medico) => (
        <MedicoItem
          className="bg-white rounded-md drop-shadow-md"
          key={medico?._id}
          id={medico?._id}
          data={medico}
          refetch={refetch}
        />
      ))}
    </SectionList>
  );
};

export default MedicosList;

import React from "react";
import ResetForm from "./ResetForm/ResetForm";

const Reset = () => {
  return (
    <section className="relative h-screen">
      <div className="absolute left-2/4 top-2/4 transform -translate-x-2/4 -translate-y-2/4">
        <ResetForm />
      </div>
    </section>
  );
};

export default Reset;

import React, { useState, useEffect, useContext } from "react";
import { ArrowLeftIcon, PlusIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Modal,
  SectionMenu,
  SectionTitle,
  SearchDateFieldInput,
  SectionLoading,
} from "../../components";
import { useGetAllAgendamentosQuery } from "../../store/services/neomedApi";
import AgendamentoList from "./AgendamentoList/AgendamentoList";
import AgendamentoStepperForm from "./AgendamentoStepperForm/AgendamentoStepperForm";
import { AuthContext } from "../../context";

const Agendamentos = () => {
  const [agendamentos, setAgendamentos] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [createAgendamentoModal, setCreateAgendamentoModal] = useState(false);
  const [agendamentoEditModal, setAgendamentoEditModal] = useState(false);
  const [agendamentoEditObj, setAgendamentoEditObj] = useState({});
  const auth = useContext(AuthContext);
  const usuarioId = auth?.user?.tipo === "8181" ? auth?.user?._id : null;

  const { data: response, refetch } = useGetAllAgendamentosQuery({
    paramsObj: { page, limit: "8", sort: "-data" },
    usuarioId,
  });

  const navigate = useNavigate();

  const toggleAgendamentoCreateModal = () => {
    setCreateAgendamentoModal((prevState) => !prevState);
  };

  const fetchData = () => {
    setPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (response && response.results !== 0) {
      setAgendamentos((prevState) => {
        const array = prevState.filter((item) => {
          const index = response?.data?.docs.findIndex(
            (doc) => doc._id === item._id
          );

          if (index === -1) {
            return true;
          }

          return false;
        });

        const toBeRendered = [...array, ...response?.data?.docs];

        return toBeRendered;
      });

      if (response.results === 8) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setHasMore(false);
    }
  }, [response, response?.results, response?.data?.docs]);

  return (
    <section className="pt-6">
      <SectionTitle>Agendamentos</SectionTitle>

      <SectionMenu styles="justify-between">
        <button type="button" onClick={() => navigate(-1)}>
          <ArrowLeftIcon className="w-6 text-shade-black" />
        </button>

        <SearchDateFieldInput
          queryHandler={useGetAllAgendamentosQuery}
          setEditModal={() => setAgendamentoEditModal(true)}
          setEditObj={setAgendamentoEditObj}
        />

        <button type="button" onClick={toggleAgendamentoCreateModal}>
          <PlusIcon className="w-6 text-shade-black" />
        </button>

        <Modal
          openState={createAgendamentoModal}
          closeFunc={toggleAgendamentoCreateModal}
        >
          <AgendamentoStepperForm
            toggleAgendamentoCreateModal={toggleAgendamentoCreateModal}
            refetch={refetch}
          />
        </Modal>

        <Modal
          openState={agendamentoEditModal}
          closeFunc={() => setAgendamentoEditModal(false)}
        >
          <AgendamentoStepperForm
            toggleAgendamentoEditModal={() => setAgendamentoEditModal(false)}
            agendamentoData={agendamentoEditObj}
            refetch={refetch}
          />
        </Modal>
      </SectionMenu>

      <InfiniteScroll
        dataLength={agendamentos.length}
        next={fetchData}
        hasMore={hasMore}
        loader={<SectionLoading />}
        endMessage={<p style={{ textAlign: "center" }}>Voce chegou no final</p>}
      >
        <AgendamentoList items={agendamentos} refetch={refetch} />
      </InfiniteScroll>
    </section>
  );
};

export default Agendamentos;

import React, { useState } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { TextEditorInput } from "../../../../components";

const ProntuarioHistoryItem = ({ prontuarioObj, toggleFunc }) => {
  const [editor, setEditor] = useState(
    EditorState.createWithContent(
      convertFromRaw(JSON.parse(prontuarioObj.orientacao))
    )
  );

  const handleOnChange = () => {
    const contentState = editor.getCurrentContent();

    const medicoData = {
      medicoNome: prontuarioObj.medicoRef?.nome,
      medicoCrm: prontuarioObj.medicoRef?.medicoInfo?.crm,
    };

    return { contentState, medicoData };
  };

  return (
    <TextEditorInput
      editorState={editor}
      setEditorState={setEditor}
      handleOnChange={handleOnChange}
      readOnly={true}
      pdfOnly={true}
      toggleFunc={toggleFunc}
    />
  );
};

export default ProntuarioHistoryItem;

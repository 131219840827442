import React from "react";
import { format } from "date-fns";

const ProntuarioItem = ({ changeStepHandler, index, createdAt, nome }) => {
  return (
    <li
      className="bg-light-green rounded-md shadow-md p-2 cursor-pointer"
      onClick={() => changeStepHandler(index)}
    >
      <div className="flex flex-col gap-4">
        <p className="text-2xl text-shade-black font-bold">
          {format(new Date(createdAt), "dd-MM-yyyy HH:mm:ss")}
        </p>

        <p className="text-xl text-shade-black font-medium">{nome}</p>
      </div>
    </li>
  );
};

export default ProntuarioItem;

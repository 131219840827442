import React, { useState } from "react";
import { format, endOfDay, parseISO, getTime, startOfDay } from "date-fns";
import Calendar from "./Calendar/Calendar";

const DatePickerSelector = ({
  pacienteRef,
  medicoRef,
  diasUnidade,
  intervaloMedico,
  next,
}) => {
  const [value, setValue] = useState(
    format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss")
  );

  const selectDayHandler = async (day) => {
    const date = format(endOfDay(day), "yyyy-MM-dd'T'HH:mm:ss");
    const initDate = getTime(startOfDay(parseISO(date)));
    const endDate = getTime(endOfDay(parseISO(date)));

    next(
      format(endOfDay(day), "yyyy-MM-dd'T'HH:mm:ss"),
      {
        hours: intervaloMedico,
        initDate,
        endDate,
      },
      { pacienteRef, medicoRef }
    );
  };

  return (
    <Calendar
      value={value}
      onChange={setValue}
      onSelectDay={selectDayHandler}
      diasUnidade={diasUnidade}
    />
  );
};

export default DatePickerSelector;

import React, { useState } from "react";
import { LocationMarkerIcon, PhoneIcon } from "@heroicons/react/solid";
import UnidadeMenu from "../UnidadeMenu/UnidadeMenu";
import UnidadeDeleteForm from "../UnidadeDeleteForm/UnidadeDeleteForm";
import UnidadeStepperForm from "../UnidadeStepperForm/UnidadeStepperForm";
import { Modal, SectionListItem } from "../../../components";

const UnidadeItem = ({ id, data, refetch }) => {
  const [unidadeEditModal, setUnidadeEditModal] = useState(false);
  const [unidadeDeleteModal, setUnidadeDeleteModal] = useState(false);

  const toggleUnidadeEditModal = () => {
    setUnidadeEditModal((prevState) => !prevState);
  };

  const toggleUnidadeDeleteModal = () => {
    setUnidadeDeleteModal((prevState) => !prevState);
  };

  return (
    <SectionListItem>
      <div className="flex items-center justify-between py-4 pl-4 pr-3">
        <p className="text-xl text-shade-black font-bold">{data.nome}</p>
        <UnidadeMenu
          showModalEditFunc={toggleUnidadeEditModal}
          showModalDeleteFunc={toggleUnidadeDeleteModal}
        />

        <Modal openState={unidadeEditModal} closeFunc={toggleUnidadeEditModal}>
          <UnidadeStepperForm
            toggleUnidadeEditModal={toggleUnidadeEditModal}
            unidadeData={{ id, ...data }}
            refetch={refetch}
          />
        </Modal>

        <Modal
          openState={unidadeDeleteModal}
          closeFunc={toggleUnidadeDeleteModal}
        >
          <UnidadeDeleteForm
            toggleUnidadeDeleteModal={toggleUnidadeDeleteModal}
            unidadeId={id}
            refetch={refetch}
          />
        </Modal>
      </div>

      <div className="flex flex-col gap-4 p-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4">
            <LocationMarkerIcon className="w-5 text-shade-green" />
            <p className="text-base text-shade-black font-medium">
              Localização:
            </p>
          </div>
          <p className="text-sm text-primary-black">{`${data.estado} - ${data.cidade}`}</p>
          <p className="text-sm text-primary-black">{`${data.bairro} - ${data.rua} - ${data.numero}`}</p>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4">
            <PhoneIcon className="w-5 text-shade-green" />
            <p className="text-base text-shade-black font-medium">Contato:</p>
          </div>
          <p className="text-sm text-primary-black">{data.telefone}</p>
        </div>
      </div>
    </SectionListItem>
  );
};

export default UnidadeItem;

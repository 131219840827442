import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { TextFieldInput, ButtonInput } from "../../../components";
import { sendResetPasswordEmail } from "../../../firebase/services/firebaseAuth";

const schema = Yup.object().shape({
  email: Yup.string().email("Formato Errado!").required("Compo Obrigatório"),
});

const ResetForm = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  const sendResetPasswordHandler = async (data) => {
    await sendResetPasswordEmail(data.email);

    navigate("/login");
  };

  return (
    <form
      className="flex flex-col gap-4"
      onSubmit={handleSubmit(sendResetPasswordHandler)}
    >
      <div className="flex flex-col gap-8">
        <img
          className="w-40 self-center"
          alt="Logo da Neomedica"
          src="/assets/Logo_Icon_SVG.svg"
        />

        <h1 className="text-4xl font-bold text-shade-black self-center">
          Reset
        </h1>
      </div>

      <TextFieldInput
        name={"email"}
        control={control}
        label="Email"
        type={"text"}
        error={errors.email}
      />

      <ButtonInput type="submit">Reset</ButtonInput>
    </form>
  );
};

export default ResetForm;

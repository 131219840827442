import React from "react";
import AdminItem from "../AdminItem/AdminItem.jsx";
import { SectionList } from "../../../components";

const AdminsList = ({ items, refetch }) => {
  return (
    <SectionList>
      {items.map((admin) => (
        <AdminItem
          className="bg-white rounded-md drop-shadow-md"
          key={admin?._id}
          id={admin?._id}
          data={admin}
          refetch={refetch}
        />
      ))}
    </SectionList>
  );
};

export default AdminsList;

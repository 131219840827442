import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextFieldInput, SelectInput, ButtonInput } from "../../../components";
import MedicoCalendarSelector from "../MedicoCalendarSelector/MedicoCalendarSelector";
import {
  useGetAllUnidadesQuery,
  useGetAllEspecialidadesQuery,
} from "../../../store/services/neomedApi";

const schema = Yup.object().shape({
  nome: Yup.string().required("Campo Obrigatório"),
  cpf: Yup.string()
    .required("Campo Obrigatorio!")
    .matches(/^[0-9-.]+$/, "Formato Errado!"),
  rg: Yup.string()
    .matches(/^[A-Za-z0-9]+$/i, "Formato Errado!")
    .required("Campo Obrigatório"),
  crm: Yup.string()
    .matches(/^[A-Za-z0-9 ]+$/i, "Formato Errado!")
    .required("Campo Obrigatório"),
  experiencia: Yup.string().required("Campo Obrigatório"),
  genero: Yup.string().required("Campo Obrigatório"),
  preco: Yup.string().matches(/^\d*(\.\d\d)?\d,?\d{0,2}$/i, "Formato Errado!"),
  unidadeRef: Yup.string().required("Campo Obrigatório"),
  especialidadeRef: Yup.string().required("Campo Obrigatório"),
});

const MedicoDepForm = ({ depFormHandler, medicoData }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      nome: medicoData ? medicoData.nome : "",
      cpf: medicoData ? medicoData.medicoInfo.cpf : "",
      rg: medicoData ? medicoData.medicoInfo.rg : "",
      crm: medicoData ? medicoData.medicoInfo.crm : "",
      experiencia: medicoData ? medicoData.medicoInfo.experiencia : "",
      genero: medicoData ? medicoData.medicoInfo.genero : "",
      preco: medicoData ? medicoData.medicoInfo.preco : "",
      unidadeRef: medicoData ? medicoData.unidadeRef._id : "",
      especialidadeRef: medicoData ? medicoData.especialidadeRef._id : "",
    },
    resolver: yupResolver(schema),
  });

  const { data: unidades } = useGetAllUnidadesQuery({
    paramsObj: { limit: "100" },
  });

  const { data: especialidades } = useGetAllEspecialidadesQuery({
    paramsObj: { limit: "100" },
  });

  const [activeDays, setActiveDays] = useState(
    medicoData ? [medicoData.medicoInfo.nascimento] : []
  );

  const medicoDependenciesHandler = (data) => {
    const array = unidades?.data?.docs;

    const index = array?.findIndex(({ _id }) => data.unidadeRef === _id);

    depFormHandler({
      ...data,
      nascimento: activeDays[0],
      unidadeInicio: array[index].horaInicial,
      unidadeFim: array[index].horaFinal,
    });
  };

  return (
    <form
      onSubmit={handleSubmit(medicoDependenciesHandler)}
      className="grid normal:grid-cols-2 small:grid-cols-2 phone:grid-cols-1 gap-9 p-4"
    >
      <TextFieldInput
        name={"nome"}
        control={control}
        label="Nome"
        error={errors?.nome}
      />

      <TextFieldInput
        name={"cpf"}
        control={control}
        label="CPF"
        error={errors?.cpf}
        mask={"999.999.999-99"}
      />

      <TextFieldInput
        name={"rg"}
        control={control}
        label="RG"
        error={errors?.rg}
      />

      <TextFieldInput
        name={"crm"}
        control={control}
        label="CRM"
        error={errors?.crm}
      />

      <MedicoCalendarSelector
        activeDays={activeDays}
        setActiveDays={setActiveDays}
        nascimento={medicoData?.medicoInfo?.nascimento}
      />

      <TextFieldInput
        name={"experiencia"}
        control={control}
        label="Experiencia"
        error={errors?.experiencia}
      />

      <SelectInput
        name={"genero"}
        control={control}
        label="Genero"
        error={errors?.genero}
      >
        {control._defaultValues.genero === "" && <option value="none" />}
        <option value="Masculino">Masculino</option>
        <option value="Feminino">Feminino</option>
      </SelectInput>

      <TextFieldInput
        name={"preco"}
        control={control}
        label="Preco"
        error={errors?.preco}
      />

      <SelectInput
        name={"unidadeRef"}
        control={control}
        label="Unidades"
        error={errors?.unidadeRef}
      >
        {control._defaultValues.unidadeRef === "" && <option value="none" />}
        {unidades?.data?.docs?.map((unidade, index) => (
          <option key={index} value={unidade._id}>
            {unidade.nome}
          </option>
        ))}
      </SelectInput>

      <SelectInput
        name={"especialidadeRef"}
        control={control}
        label="Especialidades"
        error={errors?.especialidadeRef}
      >
        {control._defaultValues.especialidadeRef === "" && (
          <option value="none" />
        )}
        {especialidades?.data?.docs?.map((especialidade, index) => (
          <option key={index} value={especialidade._id}>
            {especialidade.nome}
          </option>
        ))}
      </SelectInput>

      <ButtonInput styles={"col-span-full justify-self-center"} type={"submit"}>
        PROXIMO
      </ButtonInput>
    </form>
  );
};

export default MedicoDepForm;

import React from "react";
import { useAuth, AuthContext } from "./context";

const StartUp = ({ children }) => {
  const { user, isLoading, setUser } = useAuth();

  return (
    <AuthContext.Provider value={{ user, isLoading, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default StartUp;

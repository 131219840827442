import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  HomeIcon,
  ClipboardListIcon,
  UserCircleIcon,
} from "@heroicons/react/solid";
import { AuthContext } from "../../context";

const Home = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  return (
    <section className="pt-6">
      <div className="max-w-7xl mx-auto my-0 px-8 py-4">
        {auth?.user?.tipo === "9999" && (
          <div className="grid normal:grid-cols-3 small:grid-cols-2 phone:grid-cols-1 gap-x-9 gap-y-4">
            <button
              className="h-80 bg-primary-green rounded-md shadow-md overflow-hidden flex flex-col p-4"
              onClick={() => navigate("unidades")}
            >
              <HomeIcon className="w-80 text-light-green self-center" />
              <span className="font-bold text-4xl self-center text-shade-black">
                Unidades
              </span>
            </button>

            <button
              className="h-80 bg-primary-green rounded-md shadow-md overflow-hidden flex flex-col p-4"
              onClick={() => navigate("agendamentos")}
            >
              <ClipboardListIcon className="w-80 text-light-green self-center" />
              <span className="font-bold text-4xl self-center text-shade-black">
                Agendamentos
              </span>
            </button>

            <button
              className="h-80 bg-primary-green rounded-md shadow-md overflow-hidden flex flex-col p-4"
              onClick={() => navigate("perfil")}
            >
              <UserCircleIcon className="w-80 text-light-green self-center" />
              <span className="font-bold text-4xl self-center text-shade-black">
                Perfil
              </span>
            </button>
          </div>
        )}

        {auth?.user?.tipo === "8181" && (
          <div className="grid normal:grid-cols-3 small:grid-cols-2 phone:grid-cols-1 gap-x-9 gap-y-4">
            <button
              className="h-80 bg-primary-green rounded-md shadow-md overflow-hidden flex flex-col p-4"
              onClick={() => navigate("agendamentos")}
            >
              <ClipboardListIcon className="w-80 text-light-green self-center" />
              <span className="font-bold text-4xl self-center text-shade-black">
                Agendamentos
              </span>
            </button>

            <button
              className="h-80 bg-primary-green rounded-md shadow-md overflow-hidden flex flex-col p-4"
              onClick={() => navigate("perfil")}
            >
              <UserCircleIcon className="w-80 text-light-green self-center" />
              <span className="font-bold text-4xl self-center text-shade-black">
                Perfil
              </span>
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Home;

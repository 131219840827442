import React, { useState } from "react";
import { Controller } from "react-hook-form";
import {
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
  format,
} from "date-fns";

const TimeFieldInput = ({ name, control, label, error, setDate }) => {
  const [active, setActive] = useState(false);

  const handleActivation = (e) => {
    setActive(!!e.target.value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div className="relative h-14 border rounded-md bg-white text-primary-black border-slate-900 border-opacity-25">
          <input
            className={`outline-none w-full h-full rounded-md bg-transparent text-sm transition-all duration-200 ease-in-out px-2 py-8`}
            type={"time"}
            id={name}
            onChange={(newValue) => {
              const hour = newValue.target.value.split(":")[0];
              const minutes = newValue.target.value.split(":")[1];
              const data = setHours(
                setMinutes(
                  setSeconds(setMilliseconds(new Date(98, 0), 0), 0),
                  minutes
                ),
                hour
              );

              handleActivation(newValue);
              onChange(data.getTime());
            }}
            value={(() => {
              return format(new Date(value), "HH:mm");
            })()}
          />

          <label
            className={`absolute -top-1 left-0 flex items-center text-primary-black text-opacity-50 p-2 transition-all duration-200 ease-in-out ${
              active ? "text-xs" : "text-sm"
            }`}
            htmlFor={name}
          >
            {label}
          </label>

          {error && (
            <p className="absolute -bottom-10 left-0 text-sm text-primary-red">
              {error.message}
            </p>
          )}
        </div>
      )}
    />
  );
};

export default TimeFieldInput;

import React, { useEffect, useRef, useContext } from "react";
import { PeerContext } from "../../../context";
import { createOffer } from "../../../firebase/services/peerService";

const ParticipantsItem = ({ participant, participantKey, agendamentoId }) => {
  const { localStream, currentUser } = useContext(PeerContext);
  const videoRef = useRef(null);

  useEffect(() => {
    const remoteStream = new MediaStream();

    if (participant.peerConection) {
      participant.peerConection.ontrack = (event) => {
        event.streams[0].getTracks().forEach((track) => {
          remoteStream.addTrack(track);
        });

        videoRef.current.srcObject = remoteStream;
      };

      if (!participant.currentUser) {
        // quem cria a offer
        const currentUserKey = Object.keys(currentUser)[0];

        // definindo quem vai gerar a offer
        const sortedIds = [currentUserKey, participantKey].sort((a, b) =>
          a.localeCompare(b)
        );

        if (sortedIds[1] === currentUserKey) {
          // Create Offer
          createOffer(
            agendamentoId,
            participant.peerConection,
            sortedIds[1],
            sortedIds[0]
          );
        }
      }
    }
  }, [participant, currentUser, participantKey, agendamentoId]);

  useEffect(() => {
    if (localStream && participant.currentUser) {
      videoRef.current.srcObject = localStream;
    }
  }, [localStream, participant]);

  return (
    <li
      className={`relative h-full w-full  bg-white rounded-md shadow-md overflow-hidden`}
    >
      <video
        className={`h-full w-full object-contain`}
        ref={videoRef}
        autoPlay
        playsInline
        muted={participant.currentUser ? true : false}
      />
      <p className="absolute top-1 left-3 text-black text-2xl font-semibold">
        {participant.userName?.toUpperCase()}
      </p>
    </li>
  );
};

export default ParticipantsItem;

import React, { useState, useEffect } from "react";
import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  getTime,
  isBefore,
  isSameDay,
  isToday,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns";
import Header from "./Header/Header";

const Calendar = ({
  value,
  onChange,
  selectDayHandler,
  activeDays,
  allowPast,
}) => {
  const [days, setDays] = useState([]);

  useEffect(() => {
    const startDay = startOfWeek(startOfMonth(parseISO(value)));
    const endDay = endOfWeek(endOfMonth(parseISO(value)));
    let day = subDays(startDay, 1);
    const calendarDays = [];

    while (isBefore(day, endDay) && !isSameDay(day, endDay)) {
      calendarDays.push(
        Array(7)
          .fill(0)
          // eslint-disable-next-line no-loop-func
          .map(() => {
            day = addDays(day, 1);
            return day;
          })
      );
    }

    setDays(calendarDays);
  }, [value]);

  return (
    <div className="bg-white rounded-md shadow-md overflow-hidden">
      <Header value={value} onChange={onChange} />

      <div className="flex justify-around items-center bg-white h-8">
        {["d", "s", "t", "q", "q", "s", "s"].map((d, index) => (
          <div key={index} className="text-sm text-shade-black">
            {d.toUpperCase()}
          </div>
        ))}
      </div>

      {days.map((week, index) => (
        <div className="flex" key={index}>
          {week.map((day, index) => (
            <div
              key={index}
              className={`flex-1 flex items-center justify-center h-8 w-8 ${
                !allowPast && isBefore(day, startOfDay(new Date()))
                  ? "cursor-default"
                  : "cursor-pointer"
              } ${
                activeDays.includes(getTime(startOfDay(day))) &&
                "bg-primary-green"
              }`}
              onClick={() =>
                activeDays.includes(getTime(startOfDay(day))) || allowPast
                  ? selectDayHandler(day)
                  : !isBefore(day, startOfDay(new Date())) &&
                    selectDayHandler(day)
              }
            >
              <div
                className={`flex justify-center items-center h-7 w-7 rounded-full text-shade-black hover:bg-primary-green hover:text-light-green ${
                  isToday(day, new Date()) && "bg-slate-300"
                } ${
                  !allowPast &&
                  isBefore(day, startOfDay(new Date())) &&
                  "text-light-green pointer-events-none"
                }`}
              >
                <span className="text-sm">{format(day, "dd")}</span>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Calendar;

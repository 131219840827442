import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon, PlusIcon } from "@heroicons/react/solid";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Modal,
  SectionTitle,
  SectionLoading,
  SectionMenu,
  SearchFieldInput,
} from "../../components";
import EspecialidadesList from "./EspecialidadesList/EspecialidadesList.jsx";
import EspecialidadeCreateEditForm from "./EspecialidadeCreateEditForm/EspecialidadeCreateEditForm.jsx";
import { useGetAllEspecialidadesQuery } from "../../store/services/neomedApi";

const Especializacoes = () => {
  const [especialidades, setEspecialidades] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [especialidadeCreateModal, setEspecialidadeCreateModal] =
    useState(false);
  const [especialidadeEditModal, setEspecialidadeEditModal] = useState(false);
  const [especialidadeEditObj, setEspecialidadeEditObj] = useState({});
  const { data: response, refetch } = useGetAllEspecialidadesQuery({
    paramsObj: { page, limit: "16" },
  });
  const navigate = useNavigate();

  const toggleEspecialidadeCreateModal = () => {
    setEspecialidadeCreateModal((prevState) => !prevState);
  };

  const fetchData = () => {
    setPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (response && response.results !== 0) {
      setEspecialidades((prevState) => {
        const array = prevState.filter((item) => {
          const index = response?.data?.docs.findIndex(
            (doc) => doc._id === item._id
          );

          if (index === -1) {
            return true;
          }

          return false;
        });

        const toBeRendered = [...array, ...response?.data?.docs];

        return toBeRendered;
      });

      if (response.results === 16) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setHasMore(false);
    }
  }, [response, response?.results, response?.data?.docs]);

  return (
    <section className="pt-6">
      <SectionTitle>Especialidades</SectionTitle>

      <SectionMenu styles="justify-between">
        <button type="button" onClick={() => navigate(-1)}>
          <ArrowLeftIcon className="w-6 text-shade-black" />
        </button>

        <SearchFieldInput
          queryHandler={useGetAllEspecialidadesQuery}
          setEditModal={() => setEspecialidadeEditModal(true)}
          setEditObj={setEspecialidadeEditObj}
        />

        <button
          type="button"
          title="Criar"
          onClick={toggleEspecialidadeCreateModal}
        >
          <PlusIcon className="w-6 text-shade-black" />
        </button>

        <Modal
          openState={especialidadeCreateModal}
          closeFunc={toggleEspecialidadeCreateModal}
        >
          <EspecialidadeCreateEditForm
            toggleEspecialidadeCreateModal={toggleEspecialidadeCreateModal}
            refetch={refetch}
          />
        </Modal>

        <Modal
          openState={especialidadeEditModal}
          closeFunc={() => setEspecialidadeEditModal(false)}
        >
          <EspecialidadeCreateEditForm
            toggleEspecialidadeEditModal={() =>
              setEspecialidadeEditModal(false)
            }
            especialidadeData={especialidadeEditObj}
            refetch={refetch}
          />
        </Modal>
      </SectionMenu>

      <InfiniteScroll
        dataLength={especialidades.length}
        next={fetchData}
        hasMore={hasMore}
        loader={<SectionLoading />}
        endMessage={<p style={{ textAlign: "center" }}>Voce chegou no final</p>}
      >
        <EspecialidadesList items={especialidades} refetch={refetch} />
      </InfiniteScroll>
    </section>
  );
};

export default Especializacoes;

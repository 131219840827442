import React from "react";

const SectionMenu = ({ children, styles }) => {
  return (
    <div
      className={`max-w-7xl mx-auto my-4 px-8 py-4 flex ${
        styles ? styles : ""
      }`}
    >
      {children}
    </div>
  );
};

export default SectionMenu;

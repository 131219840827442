import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextFieldInput, ButtonInput } from "../../../components";
import {
  useCreateEspecialidadeMutation,
  useUpdateEspecialidadeMutation,
} from "../../../store/services/neomedApi";

const schema = Yup.object().shape({
  nome: Yup.string().required("Campo Obrigatório"),
  descricao: Yup.string().required("Campo Obrigatório"),
});

const EspecialidadeCreateEditForm = ({
  toggleEspecialidadeCreateModal,
  toggleEspecialidadeEditModal,
  especialidadeData,
  refetch,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nome: especialidadeData ? especialidadeData.nome : "",
      descricao: especialidadeData ? especialidadeData.descricao : "",
    },
    resolver: yupResolver(schema),
  });
  const [createEspecialidade] = useCreateEspecialidadeMutation();
  const [updateEspecialidade] = useUpdateEspecialidadeMutation();

  const createEspecialidadeHandler = async (data) => {
    const especialidadeObj = {
      ...data,
    };

    const { data: response } = await createEspecialidade({ especialidadeObj });

    if (response?.status === "success") {
      refetch();
    }

    toggleEspecialidadeCreateModal();
  };

  const editEspecialidadeHandler = async (data) => {
    const especialidadeObj = {
      ...data,
    };

    const { data: response } = await updateEspecialidade({
      especialidadeId: especialidadeData._id,
      especialidadeObj,
    });

    if (response?.status === "success") {
      refetch();
    }

    toggleEspecialidadeEditModal();
  };

  return (
    <form
      onSubmit={
        especialidadeData
          ? handleSubmit(editEspecialidadeHandler)
          : handleSubmit(createEspecialidadeHandler)
      }
      className="grid normal:grid-cols-2 small:grid-cols-2 phone:grid-cols-1 gap-9 p-4"
    >
      <TextFieldInput
        name={"nome"}
        control={control}
        label={"nome"}
        error={errors?.nome}
      />

      <TextFieldInput
        name={"descricao"}
        control={control}
        label="Descrição"
        error={errors?.descricao}
      />

      <ButtonInput styles={"col-span-full justify-self-center"} type={"submit"}>
        {especialidadeData ? "EDITAR" : "CADASTRAR"}
      </ButtonInput>
    </form>
  );
};

export default EspecialidadeCreateEditForm;

import React, { useState, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import InfiniteScroll from "react-infinite-scroll-component";
import UnidadeList from "./UnidadeList/UnidadeList";
import {
  Modal,
  SectionTitle,
  SectionMenu,
  SearchFieldInput,
  SectionLoading,
} from "../../components";
import { useGetAllUnidadesQuery } from "../../store/services/neomedApi";
import UnidadeStepperForm from "./UnidadeStepperForm/UnidadeStepperForm";

const Unidades = () => {
  const [unidades, setUnidades] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [unidadeCreateModal, setUnidadeCreateModal] = useState(false);
  const [unidadeEditModal, setUnidadeEditModal] = useState(false);
  const [unidadeEditObj, setUnidadeEditObj] = useState({});
  const { data: response, refetch } = useGetAllUnidadesQuery({
    paramsObj: { page, limit: "8" },
  });

  const toggleUnidadeCreateModal = () => {
    setUnidadeCreateModal((prevState) => !prevState);
  };

  const fetchData = () => {
    setPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (response && response.results !== 0) {
      setUnidades((prevState) => {
        const array = prevState.filter((item) => {
          const index = response?.data?.docs.findIndex(
            (doc) => doc._id === item._id
          );

          if (index === -1) {
            return true;
          }

          return false;
        });

        const toBeRendered = [...array, ...response?.data?.docs];

        return toBeRendered;
      });

      if (response.results === 8) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setHasMore(false);
    }
  }, [response, response?.results, response?.data?.docs]);

  return (
    <section className="pt-6">
      <SectionTitle>Unidades</SectionTitle>

      <SectionMenu styles="justify-between">
        <div className="invisible" />

        <SearchFieldInput
          queryHandler={useGetAllUnidadesQuery}
          setEditModal={() => setUnidadeEditModal(true)}
          setEditObj={setUnidadeEditObj}
        />

        <button type="button" title="Criar" onClick={toggleUnidadeCreateModal}>
          <PlusIcon className="w-6 text-shade-black" />
        </button>

        <Modal
          openState={unidadeCreateModal}
          closeFunc={toggleUnidadeCreateModal}
        >
          <UnidadeStepperForm
            toggleUnidadeCreateModal={toggleUnidadeCreateModal}
            refetch={refetch}
          />
        </Modal>

        <Modal
          openState={unidadeEditModal}
          closeFunc={() => setUnidadeEditModal(false)}
        >
          <UnidadeStepperForm
            toggleUnidadeEditModal={() => setUnidadeEditModal(false)}
            unidadeData={unidadeEditObj}
            refetch={refetch}
          />
        </Modal>
      </SectionMenu>

      <InfiniteScroll
        dataLength={unidades.length}
        next={fetchData}
        hasMore={hasMore}
        loader={<SectionLoading />}
        endMessage={<p style={{ textAlign: "center" }}>Voce chegou no final</p>}
      >
        <UnidadeList items={unidades} refetch={refetch} />
      </InfiniteScroll>
    </section>
  );
};

export default Unidades;

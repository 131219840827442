import React, { useState } from "react";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";

const TextFieldInput = ({ name, control, label, type, error, mask }) => {
  const [active, setActive] = useState(
    control._defaultValues[name] !== "" ? true : false
  );

  const handleActivation = (e) => {
    setActive(!!e.target.value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div
          className={`relative h-14 border rounded-md bg-white text-primary-black border-opacity-25 ${
            error ? "border-primary-red" : "border-slate-900"
          }`}
        >
          {mask ? (
            <InputMask
              mask={mask}
              value={value}
              onChange={(newValue) => {
                handleActivation(newValue);
                onChange(newValue);
              }}
            >
              {(inputProps) => (
                <input
                  {...inputProps}
                  className={`outline-none w-full h-full rounded-md bg-transparent text-sm transition-all duration-200 ease-in-out p-2`}
                  type={type}
                  id={name}
                />
              )}
            </InputMask>
          ) : (
            <input
              className={`outline-none w-full h-full rounded-md bg-transparent text-sm transition-all duration-200 ease-in-out p-2`}
              type={type}
              id={name}
              value={value}
              onChange={(newValue) => {
                handleActivation(newValue);
                onChange(newValue);
              }}
            />
          )}

          <label
            className={`absolute flex items-center text-primary-black text-opacity-50 p-2 transition-all duration-200 ease-in-out ${
              active
                ? "text-xs -top-1 left-0"
                : "text-sm top-2/4 transform -translate-y-2/4"
            }`}
            htmlFor={name}
          >
            {label}
          </label>

          {error && (
            <p className="absolute -bottom-6 left-0 text-sm text-primary-red">
              {error.message}
            </p>
          )}
        </div>
      )}
    />
  );
};

export default TextFieldInput;

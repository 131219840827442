import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextFieldInput, SelectInput, ButtonInput } from "../../../components";
import PacienteCalendarSelector from "../PacienteCalendarSelector/PacienteCalendarSelector";

const schema = Yup.object().shape({
  nome: Yup.string().required("Campo Obrigatório"),
  cpf: Yup.string()
    .required("Campo Obrigatorio!")
    .matches(/^[0-9-.]+$/, "Formato Errado!"),
  rg: Yup.string()
    .required("Campo Obrigatorio!")
    .matches(/^[A-Za-z0-9]+$/i, "Formato errado!"),
  nacionalidade: Yup.string()
    .matches(/^[A-Za-z0-9]+$/i, "Formato errado!")
    .required("Campo Obrigatorio!"),
  cns: Yup.string()
    .matches(/^[0-9]{15}$/, "Formato errado!")
    .required("Campo Obrigatorio!"),
  genero: Yup.string()
    .matches(/^[A-Za-z0-9]+$/i, "Formato errado!")
    .required("Campo Obrigatorio!"),
  convenio: Yup.string()
    .matches(/^[A-Za-z0-9]+$/i, "Formato errado!")
    .required("Campo Obrigatorio!"),
  encPor: Yup.string()
    .matches(/^[A-Za-z0-9 ]+$/i, "Formato errado!")
    .required("Campo obrigatorio!"),
});

const PacienteCreateForm = ({ next, pacienteData }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      nome: pacienteData ? pacienteData.nome : "",
      cpf: pacienteData ? pacienteData.pacienteInfo.cpf : "",
      rg: pacienteData ? pacienteData.pacienteInfo.rg : "",
      nacionalidade: pacienteData
        ? pacienteData.pacienteInfo.nacionalidade
        : "",
      cns: pacienteData ? pacienteData.pacienteInfo.cns : "",
      genero: pacienteData ? pacienteData.pacienteInfo.genero : "",
      convenio: pacienteData ? pacienteData.pacienteInfo.convenio : "",
      encPor: pacienteData ? pacienteData.pacienteInfo.encPor : "",
    },
    resolver: yupResolver(schema),
  });
  const [activeDays, setActiveDays] = useState(
    pacienteData ? [pacienteData.pacienteInfo.nascimento] : []
  );

  const nextStepHandler = async (data) => {
    next({ ...data, nascimento: activeDays[0] });
  };

  return (
    <form
      onSubmit={handleSubmit(nextStepHandler)}
      className="grid normal:grid-cols-2 small:grid-cols-2 phone:grid-cols-1 gap-9 p-4"
    >
      <TextFieldInput
        name={"nome"}
        control={control}
        label="Nome"
        error={errors?.nome}
      />

      <TextFieldInput
        name={"cpf"}
        control={control}
        label="CPF"
        error={errors?.cpf}
        mask={"999.999.999-99"}
      />

      <TextFieldInput
        name={"rg"}
        control={control}
        label="Carteira de Identidade (RG)"
        error={errors?.rg}
      />

      <SelectInput
        name={"nacionalidade"}
        control={control}
        label="Nacionalidade"
        error={errors?.nacionalidade}
      >
        {control._defaultValues.nacionalidade === "" && <option value="none" />}
        <option value="Brasileiro">Brasileiro</option>
        <option value="Estrangeiro">Estrangeiro</option>
      </SelectInput>

      <TextFieldInput
        name={"cns"}
        control={control}
        label="Cartão Nacional de Saude"
        error={errors?.cns}
      />

      <PacienteCalendarSelector
        activeDays={activeDays}
        setActiveDays={setActiveDays}
        nascimento={pacienteData?.pacienteInfo?.nascimento}
      />

      <SelectInput
        name={"genero"}
        control={control}
        label="Genero"
        error={errors?.genero}
      >
        {control._defaultValues.genero === "" && <option value="none" />}
        <option value={"Masculino"}>Masculino</option>
        <option value={"Feminino"}>Feminino</option>
      </SelectInput>

      <SelectInput
        name={"convenio"}
        control={control}
        label="Convênio"
        error={errors?.convenio}
      >
        {control._defaultValues.convenio === "" && <option value="none" />}
        <option value={"Individual"}>Individual</option>
        <option value={"Familiar"}>Familiar</option>
        <option value={"Coletivos"}>Coletivos</option>
        <option value={"Empresarial"}>Empresarial</option>
      </SelectInput>

      <TextFieldInput
        name={"encPor"}
        control={control}
        label="Encaminhado Por"
        error={errors?.encPor}
      />

      <ButtonInput styles={"col-span-full justify-self-center"} type={"submit"}>
        PROXIMO
      </ButtonInput>
    </form>
  );
};

export default PacienteCreateForm;

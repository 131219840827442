import React, { useState } from "react";
import { Stepper } from "../../../components";
import AdminCreateForm from "../AdminCreateForm/AdminCreateForm";
import AdminDetailsForm from "../AdminDetailsForm/AdminDetailsForm";

const AdminStepperForm = ({ toggleAdminsCreateModal, refetch }) => {
  const [stepNumber, setStepNumber] = useState(0);
  const [stepOneData, setStepOneData] = useState({});

  const next = (stepOneData) => {
    setStepNumber((prevState) => prevState + 1);
    setStepOneData(stepOneData);
  };

  return (
    <Stepper
      stepNumber={stepNumber}
      labels={["Criar o admin", "Detalhes do admin"]}
    >
      <AdminCreateForm next={next} />
      <AdminDetailsForm
        stepOneData={stepOneData}
        toggleAdminsCreateModal={toggleAdminsCreateModal}
        refetch={refetch}
      />
    </Stepper>
  );
};

export default AdminStepperForm;

import React from "react";
import { useDeleteUsuarioMutation } from "../../../store/services/neomedApi";

const PacienteDeleteForm = ({
  togglePacienteDeleteModal,
  usuarioId,
  refetch,
}) => {
  const [deletePaciente, { error }] = useDeleteUsuarioMutation();

  const deletePacienteHandler = async () => {
    await deletePaciente({ usuarioId });

    if (!error) {
      refetch();
    }

    togglePacienteDeleteModal();
  };

  return (
    <div className="flex flex-col gap-9 p-4">
      <p className="self-center">Tem Certeza ?</p>
      <button
        type="button"
        className="self-center bg-primary-red rounded-md p-4 text-sm text-primary-black font-medium tracking-widest"
        onClick={deletePacienteHandler}
      >
        REMOVER
      </button>
    </div>
  );
};

export default PacienteDeleteForm;

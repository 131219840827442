import React, { useState, useContext } from "react";
import {
  PhoneIcon,
  DeviceMobileIcon,
  UserCircleIcon,
  PencilIcon,
} from "@heroicons/react/solid";
import { AuthContext } from "../../context";
import { Modal, ButtonInput } from "../../components";
import { deleteFile } from "../../firebase/services/firebaseStorage";
import ImageUploadPreview from "./ImageUploadPreview/ImageUploadPreview";
import PerfilEditForm from "./PerfilEditForm/PerfilEditForm";

const Perfil = () => {
  const [imageEditModal, setImageEditModal] = useState(false);
  const [userEditModal, setUserEditModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const auth = useContext(AuthContext);

  const toggleAndDeleteImageModal = async () => {
    if (imageUrl) {
      await deleteFile(imageUrl);
    }

    setImageEditModal((prevState) => !prevState);
  };

  const toggleImageModal = () => {
    setImageEditModal((prevState) => !prevState);
  };

  const toggleUserEditModal = () => {
    setUserEditModal((prevState) => !prevState);
  };

  return (
    <section className="max-w-7xl mx-auto my-0 px-8 py-4">
      <div className="bg-white rounded-md shadow-md p-4">
        <div className="flex justify-between normal:items-end small:items-end phone:items-center phone:flex-col phone:gap-4">
          <div className="flex gap-6 phone:flex-col phone:items-center">
            <div className="w-32 h-32 rounded-full relative">
              {auth?.user?.imageUrl ? (
                <img
                  className="w-32 h-32 rounded-full"
                  src={auth?.user?.imageUrl}
                  alt={auth?.user?.imageUrl}
                />
              ) : (
                <UserCircleIcon className="w-full text-primary-black" />
              )}

              <button
                className="flex items-center justify-center w-8 h-8 bg-primary-green rounded-full absolute bottom-0 right-0 transform -translate-x-2/4 hover:scale-150"
                type="button"
                onClick={toggleImageModal}
              >
                <PencilIcon className="w-6" />
              </button>

              <Modal
                openState={imageEditModal}
                closeFunc={toggleAndDeleteImageModal}
              >
                <ImageUploadPreview
                  basePath={"users"}
                  setImageUrl={setImageUrl}
                  toggleAndDeleteImageModal={toggleAndDeleteImageModal}
                  toggleImageModal={toggleImageModal}
                />
              </Modal>
            </div>

            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <p className="text-xl text-shade-black font-bold">
                  {auth?.user?.nome ? auth?.user?.nome : "Indefinido!"}
                </p>
                <p className="text-sm text-primary-black">
                  {auth?.user?.email}
                </p>
              </div>

              <div className="flex gap-9">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4">
                    <PhoneIcon className="w-5 text-shade-green" />
                    <p className="text-base text-shade-black font-medium">
                      Telefone
                    </p>
                  </div>

                  <p className="text-sm text-primary-black">
                    {auth?.user?.telefone
                      ? auth?.user?.telefone
                      : "Indefinido!"}
                  </p>
                </div>

                <div className="flex flex-col gap-2">
                  <div className="flex gap-4">
                    <DeviceMobileIcon className="w-5 text-shade-green" />
                    <p className="text-base text-shade-black font-medium">
                      Celular
                    </p>
                  </div>

                  <p className="text-sm text-primary-black">
                    {auth?.user?.celular ? auth?.user?.celular : "Indefinido!"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <ButtonInput type={"button"} onClick={toggleUserEditModal}>
            <PencilIcon className="w-5 text-primary-black" />
            Editar
          </ButtonInput>

          <Modal openState={userEditModal} closeFunc={toggleUserEditModal}>
            <PerfilEditForm
              toggleUserEditModal={toggleUserEditModal}
              userData={{
                nome: auth?.user?.nome,
                telefone: auth?.user?.telefone,
                celular: auth?.user?.celular,
              }}
            />
          </Modal>
        </div>
      </div>
    </section>
  );
};

export default Perfil;

import React, { useState, useContext } from "react";
import { Stepper } from "../../../components";
import PacienteSelector from "../PacienteSelector/PacienteSelector";
import MedicoSelector from "../MedicoSelector/MedicoSelector";
import DataSelector from "../DataSelector/DataSelector";
import HourSelector from "../HourSelector/HourSelector";
import { AuthContext } from "../../../context";

const AgendamentoStepperForm = ({
  toggleAgendamentoCreateModal,
  toggleAgendamentoEditModal,
  agendamentoData,
  refetch,
}) => {
  const [stepNumber, setStepNumber] = useState(0);
  const [pacienteRef, setPacienteRef] = useState("");
  const [medicoRef, setMedicoRef] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [dateConstraints, setDateConstraints] = useState({});
  const [usersData, setUsersData] = useState({});
  const auth = useContext(AuthContext);
  const labels =
    auth?.user?.tipo === "8181"
      ? ["Escolha o Paciente", "Escolha o dia", "Escolha a Hora"]
      : [
          "Escolha o Paciente",
          "Escolha o Medico",
          "Escolha o dia",
          "Escolha a Hora",
        ];

  const pacienteSelectorHandler = (pacienteId) => {
    setStepNumber((prevState) => prevState + 1);
    setPacienteRef(pacienteId);
  };

  const medicoSelectorHandler = (medicoUser) => {
    setStepNumber((prevState) => prevState + 1);
    setMedicoRef(medicoUser);
  };

  const DataSelectorHandler = (date, dateLimits, usersData) => {
    setSelectedDate(date);
    setDateConstraints(dateLimits);
    setUsersData(usersData);
    setStepNumber((prevState) => prevState + 1);
  };

  return (
    <Stepper stepNumber={stepNumber} labels={labels}>
      <PacienteSelector next={pacienteSelectorHandler} />
      {auth?.user?.tipo !== "8181" && (
        <MedicoSelector next={medicoSelectorHandler} />
      )}
      <DataSelector
        pacienteRef={pacienteRef}
        medicoRef={medicoRef}
        next={DataSelectorHandler}
      />
      <HourSelector
        selectedDate={selectedDate}
        dateConstraints={dateConstraints}
        usersData={usersData}
        toggleAgendamentoCreateModal={toggleAgendamentoCreateModal}
        toggleAgendamentoEditModal={toggleAgendamentoEditModal}
        agendamentoData={agendamentoData}
        refetch={refetch}
      />
    </Stepper>
  );
};

export default AgendamentoStepperForm;

import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextFieldInput, ButtonInput } from "../../../components";
import { loginUser } from "../../../firebase/services/firebaseAuth";

const schema = Yup.object().shape({
  email: Yup.string().email("Formato Errado!").required("Campo Obrigatório"),
  password: Yup.string()
    .min(6, "Campo deve ter no mínimo 6 letras")
    .required("Campo Obrigatório"),
});

const LoginForm = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const handleLogin = async (data) => {
    await loginUser(data.email, data.password);
    reset();

    navigate("/");
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={handleSubmit(handleLogin)}>
      <div className="flex flex-col gap-8">
        <img
          className="w-40 self-center"
          alt="Logo da Neomedica"
          src="/assets/Logo_Icon_SVG.svg"
        />

        <h1 className="text-4xl font-bold text-shade-black self-center">
          Login
        </h1>
      </div>

      <TextFieldInput
        name={"email"}
        control={control}
        label="Email"
        type={"text"}
        error={errors.email}
      />

      <TextFieldInput
        name={"password"}
        control={control}
        label="Senha"
        type={"password"}
        error={errors.password}
      />

      <ButtonInput type="submit">Login</ButtonInput>

      <p
        className="font-light text-light-black self-center cursor-pointer"
        onClick={() => navigate("/reset")}
      >
        Esqueceu a senha ?
      </p>
    </form>
  );
};

export default LoginForm;

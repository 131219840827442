import React, { useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { UserCircleIcon, LogoutIcon, UserIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import HeaderAdminMenu from "../HeaderAdminMenu/HeaderAdminMenu";
import { logoutUser } from "../../../firebase/services/firebaseAuth";
import { AuthContext } from "../../../context";

const HeaderMenu = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  return (
    <Menu as="div" className="relative">
      <Menu.Button title="Opções de Usuário">
        {auth?.user?.imageUrl ? (
          <img
            className="w-12 h-12 rounded-full"
            src={auth?.user?.imageUrl}
            alt={auth?.user?.imageUrl}
          />
        ) : (
          <UserCircleIcon className="w-12 text-primary-black" />
        )}
      </Menu.Button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="flex flex-col bg-white shadow-md rounded-lg w-56 px-1 py-1 absolute right-0 z-10">
          {auth?.user?.tipo !== "1199" && (
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => navigate("/perfil")}
                  title="Perfil"
                  className={`${
                    active
                      ? "bg-primary-green text-light-green"
                      : "text-primary-black"
                  } flex items-center gap-2 rounded-md px-1 py-1`}
                >
                  <UserIcon className="w-5 h-5 fill-current" />
                  <span>Perfil</span>
                </button>
              )}
            </Menu.Item>
          )}

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={logoutUser}
                title="Logout"
                className={`${
                  active
                    ? "bg-primary-green text-light-green"
                    : "text-primary-black"
                } flex items-center gap-2 rounded-md px-1 py-1`}
              >
                <LogoutIcon className="w-5 h-5 fill-current" />
                <span>Logout</span>
              </button>
            )}
          </Menu.Item>

          {auth?.user?.tipo === "9999" && (
            <HeaderAdminMenu navigate={navigate} />
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default HeaderMenu;

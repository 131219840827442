import React from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import {
  format,
  parseISO,
  endOfDay,
  subMonths,
  addMonths,
  isThisMonth,
} from "date-fns";

const CalendarHeader = ({ value, onChange }) => {
  const currMonth = () => {
    return format(parseISO(value), "MM");
  };

  const currYear = () => {
    return format(parseISO(value), "yyyy");
  };

  const thisMonth = () => {
    return isThisMonth(parseISO(value), new Date());
  };

  const prevMonth = () => {
    const prevMonthDate = subMonths(parseISO(value), 1);

    return format(endOfDay(prevMonthDate), "yyyy-MM-dd'T'HH:mm:ss");
  };

  const nextMonth = () => {
    const nextMonthDate = addMonths(parseISO(value), 1);

    return format(endOfDay(nextMonthDate), "yyyy-MM-dd'T'HH:mm:ss");
  };

  return (
    <div className="flex items-center justify-around w-full bg-primary-green p-4">
      <button type="button" onClick={() => !thisMonth() && onChange(prevMonth)}>
        {!thisMonth() && <ArrowLeftIcon className="w-6 text-gray-800 " />}
      </button>

      <div className="flex gap-2">
        <p className="text-xl font-bold text-shade-black">{currMonth()}</p>
        <p className="text-xl font-bold text-shade-black">{currYear()}</p>
      </div>

      <button type="button" onClick={() => onChange(nextMonth)}>
        <ArrowRightIcon className="w-6 text-gray-800 " />
      </button>
    </div>
  );
};

export default CalendarHeader;

import React, { useState } from "react";
import { endOfDay, format } from "date-fns";
import { Menu } from "@headlessui/react";
import Calendar from "../../Calendar";

const DaySelector = ({ selectDayHandler }) => {
  const [value, setValue] = useState(
    format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss")
  );

  return (
    <Menu.Item>
      {() => (
        <Calendar
          value={value}
          onChange={setValue}
          selectDayHandler={selectDayHandler}
          activeDays={[]}
          allowPast
        />
      )}
    </Menu.Item>
  );
};

export default DaySelector;

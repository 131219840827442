import React, { useState } from "react";
import {
  HomeIcon,
  UserCircleIcon,
  DeviceMobileIcon,
} from "@heroicons/react/solid";
import { Modal, SectionListItem } from "../../../components";
import AdminMenu from "../AdminMenu/AdminMenu.jsx";
import AdminDetailsForm from "../AdminDetailsForm/AdminDetailsForm";

const AdminItem = ({ id, data, refetch }) => {
  const [adminEditModal, setAdminEditModal] = useState(false);

  const toggleAdminEditModal = () => {
    setAdminEditModal((prevState) => !prevState);
  };

  return (
    <SectionListItem>
      <div className="flex items-center justify-between pt-4 pb-1 pl-4 pr-3">
        <div className="flex gap-4">
          {data?.funcionarioInfo?.imageUrl ? (
            <img
              className="w-10 h-10 rounded-full"
              src={data.funcionarioInfo.imageUrl}
              alt={data.funcionarioInfo.imageUrl}
            />
          ) : (
            <UserCircleIcon className="w-12 text-primary-black" />
          )}

          <p className="self-center text-base text-shade-black font-bold">
            {data.nome}
          </p>
        </div>

        <AdminMenu showModalEditFunc={toggleAdminEditModal} />

        <Modal openState={adminEditModal} closeFunc={toggleAdminEditModal}>
          <AdminDetailsForm
            toggleAdminEditModal={toggleAdminEditModal}
            adminData={{ id, ...data }}
            refetch={refetch}
          />
        </Modal>
      </div>

      <div className="flex flex-col gap-4 p-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4">
            <HomeIcon className="w-5 text-shade-green" />
            <p className="text-base text-shade-black font-medium">
              Clínica de atendimento:
            </p>
          </div>
          <p className="text-sm text-primary-black">
            {data.unidadeRef ? data.unidadeRef.nome : "Não vinculado"}
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4">
            <DeviceMobileIcon className="w-5 text-shade-green" />
            <p className="text-base text-shade-black font-medium">Celular:</p>
          </div>
          <p className="text-sm text-primary-black">{data.celular}</p>
        </div>
      </div>
    </SectionListItem>
  );
};

export default AdminItem;

import React, { useState } from "react";
import { Modal, SectionListItem } from "../../../components";
import EspecialidadeCreateEditForm from "../EspecialidadeCreateEditForm/EspecialidadeCreateEditForm.jsx";
import EspecialidadeMenu from "../EspecialidadeMenu/EspecialidadeMenu.jsx";
import EspecialidadeDeleteForm from "../EspecialidadesDeleteForm/EspecialidadesDeleteForm.jsx";

const EspecialidadeItem = ({ id, data, refetch }) => {
  const [especialidadeEditModal, setEspecialidadeEditModal] = useState(false);
  const [especialidadeDeleteModal, setEspecialidadeDeleteModal] =
    useState(false);

  const toggleEspecialidadeEditModal = () => {
    setEspecialidadeEditModal((prevState) => !prevState);
  };

  const toggleEspecialidadeDeleteModal = () => {
    setEspecialidadeDeleteModal((prevState) => !prevState);
  };

  return (
    <SectionListItem>
      <div className="flex items-center justify-between py-4 pl-4 pr-3">
        <p className="text-xl text-shade-black font-bold">{data.nome}</p>
        <EspecialidadeMenu
          showModalEditFunc={toggleEspecialidadeEditModal}
          showModalDeleteFunc={toggleEspecialidadeDeleteModal}
        />

        <Modal
          openState={especialidadeEditModal}
          closeFunc={toggleEspecialidadeEditModal}
        >
          <EspecialidadeCreateEditForm
            toggleEspecialidadeEditModal={toggleEspecialidadeEditModal}
            especialidadeData={{ id, ...data }}
            refetch={refetch}
          />
        </Modal>

        <Modal
          openState={especialidadeDeleteModal}
          closeFunc={toggleEspecialidadeDeleteModal}
        >
          <EspecialidadeDeleteForm
            toggleEspecialidadeDeleteModal={toggleEspecialidadeDeleteModal}
            especialidadeId={id}
            refetch={refetch}
          />
        </Modal>
      </div>

      <div className="flex flex-col gap-4 p-4">
        <div className="flex flex-col gap-2">
          <p className="text-sm text-primary-black">{data.descricao}</p>
        </div>
      </div>
    </SectionListItem>
  );
};

export default EspecialidadeItem;

import React from "react";
import UnidadeItem from "../UnidadeItem/UnidadeItem";
import { SectionList } from "../../../components";

const UnidadeList = ({ items, refetch }) => {
  return (
    <SectionList>
      {items?.map((unidade) => (
        <UnidadeItem
          className="bg-white rounded-md drop-shadow-md"
          key={unidade._id}
          id={unidade._id}
          data={unidade}
          refetch={refetch}
        />
      ))}
    </SectionList>
  );
};

export default UnidadeList;

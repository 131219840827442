import React, { useState } from "react";
import {
  PhoneIcon,
  DeviceMobileIcon,
  VideoCameraIcon,
} from "@heroicons/react/solid";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { SectionListItem, Modal } from "../../../components";
import { useUpdateAgendamentoMutation } from "../../../store/services/neomedApi";
import AgendamentoMenu from "../AgendamentoMenu/AgendamentoMenu";
import AgendamentoStepperForm from "../AgendamentoStepperForm/AgendamentoStepperForm";
import AgendamentoCancelForm from "../AgendamentoCancelForm/AgendamentoCancelForm";

const AgendamentoItem = ({ id, data, refetch }) => {
  const [cancelAgendamentoModal, setCancelAgendamentoModal] = useState(false);
  const [editAgendamentoModal, setEditAgendamentoModal] = useState(false);
  const [updateAgendamento] = useUpdateAgendamentoMutation();

  const navigate = useNavigate();

  const webConferenceStartHandler = async () => {
    const agendamentoObj = {
      andamento: "Iniciado",
    };

    const { data: response } = await updateAgendamento({
      agendamentoId: id,
      agendamentoObj,
    });

    if (response?.status === "success") {
      navigate(`/agendamentos/${id}`);
    }
  };

  const toggleCancelAgendamentoModal = () => {
    setCancelAgendamentoModal((prevState) => !prevState);
  };

  const toggleAgendamentoEditModal = () => {
    setEditAgendamentoModal((prevState) => !prevState);
  };

  return (
    <SectionListItem>
      <div className="flex justify-between p-4">
        <p className="text-xl text-shade-black font-bold">
          {format(new Date(data.data), "dd-MM-yyyy HH:mm:ss")}
        </p>

        <div className="flex items-start gap-4">
          <div
            className={`h-5 w-5 ${
              data.andamento === "Encerrado"
                ? "bg-primary-red"
                : data.andamento === "Iniciado"
                ? "bg-primary-green"
                : data.andamento === "Pendente"
                ? "bg-slate-400"
                : data.andamento === "Cancelado"
                ? "bg-black"
                : ""
            } rounded-full relative group`}
          >
            <p className="opacity-0 pointer-events-none invisible bg-slate-800 text-white p-2 rounded-md absolute left-2/4 top-2/4 transition-all duration-200 ease-out transform -translate-x-2/4 -translate-y-2/4 group-hover:opacity-100 group-hover:visible group-hover:translate-y-6 z-10">
              {data.andamento}
            </p>
          </div>

          {data.andamento === "Encerrado" ||
          data.andamento === "Cancelado" ? null : (
            <VideoCameraIcon
              className="w-5 text-shade-green cursor-pointer"
              onClick={webConferenceStartHandler}
            />
          )}

          <AgendamentoMenu
            pacienteRef={data.pacienteRef._id}
            toggleCancelAgendamentoModal={toggleCancelAgendamentoModal}
            toggleEditAgendamentoModal={toggleAgendamentoEditModal}
            status={data.andamento}
          />

          <Modal
            closeFunc={toggleCancelAgendamentoModal}
            openState={cancelAgendamentoModal}
          >
            <AgendamentoCancelForm
              toggleCancelAgendamentoModal={toggleCancelAgendamentoModal}
              agendamentoId={id}
              refetch={refetch}
            />
          </Modal>

          <Modal
            closeFunc={toggleAgendamentoEditModal}
            openState={editAgendamentoModal}
          >
            <AgendamentoStepperForm
              toggleAgendamentoEditModal={toggleAgendamentoEditModal}
              agendamentoData={{ id, ...data }}
              refetch={refetch}
            />
          </Modal>
        </div>
      </div>

      <div className="flex justify-between p-4">
        <div className="flex flex-col gap-2">
          <p className="self-center text-base text-shade-black font-bold">
            {data.pacienteRef?.nome}
          </p>

          <div className="flex flex-col gap-2">
            <div className="flex gap-4">
              <PhoneIcon className="w-5 text-shade-green" />
              <p className="text-base text-shade-black font-medium">Celular:</p>
            </div>

            <p>{data.pacienteRef?.celular}</p>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex gap-4">
              <DeviceMobileIcon className="w-5 text-shade-green" />
              <p className="text-base text-shade-black font-medium">
                Telefone:
              </p>
            </div>

            <p>{data.pacienteRef?.telefone}</p>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <p className="self-center text-base text-shade-black font-bold">
            {data.medicoRef?.nome}
          </p>

          <div className="flex flex-col gap-2">
            <div className="flex gap-4">
              <PhoneIcon className="w-5 text-shade-green" />
              <p className="text-base text-shade-black font-medium">Celular:</p>
            </div>

            <p>{data.medicoRef?.celular}</p>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex gap-4">
              <DeviceMobileIcon className="w-5 text-shade-green" />
              <p className="text-base text-shade-black font-medium">
                Telefone:
              </p>
            </div>

            <p>{data.medicoRef?.telefone}</p>
          </div>
        </div>
      </div>
    </SectionListItem>
  );
};

export default AgendamentoItem;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon, PlusIcon } from "@heroicons/react/solid";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  SectionTitle,
  SectionMenu,
  Modal,
  SearchFieldInput,
  SectionLoading,
} from "../../components";
import MedicosList from "./MedicosList";
import { useGetAllUsuariosQuery } from "../../store/services/neomedApi";
import MedicoStepperForm from "./MedicoStepperForm/MedicoStepperForm";

const Medicos = () => {
  const [medicos, setMedicos] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [medicosCreateModel, setMedicoCreateModel] = useState(false);
  const [medicoEditModal, setMedicoEditModal] = useState(false);
  const [medicoEditObj, setMedicoEditObj] = useState({});
  const { data: response, refetch } = useGetAllUsuariosQuery({
    paramsObj: { page, limit: "8", tipo: 8181 },
  });

  const navigate = useNavigate();

  const toggleMedicosCreateModal = () => {
    setMedicoCreateModel((prevState) => !prevState);
  };

  const fetchData = () => {
    setPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (response && response.results !== 0) {
      setMedicos((prevState) => {
        const array = prevState.filter((item) => {
          const index = response?.data?.docs.findIndex(
            (doc) => doc._id === item._id
          );

          if (index === -1) {
            return true;
          }

          return false;
        });

        const toBeRendered = [...array, ...response?.data?.docs];

        return toBeRendered;
      });

      if (response.results === 8) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setHasMore(false);
    }
  }, [response, response?.results, response?.data?.docs]);

  return (
    <section className="pt-6">
      <SectionTitle>Médicos</SectionTitle>

      <SectionMenu styles="justify-between">
        <button type="button" onClick={() => navigate(-1)}>
          <ArrowLeftIcon className="w-6 text-shade-black" />
        </button>

        <SearchFieldInput
          queryHandler={useGetAllUsuariosQuery}
          queryParams={{ tipo: 8181 }}
          setEditModal={() => setMedicoEditModal(true)}
          setEditObj={setMedicoEditObj}
        />

        <button type="button" onClick={toggleMedicosCreateModal}>
          <PlusIcon className="w-6 text-shade-black" />
        </button>

        <Modal
          openState={medicosCreateModel}
          closeFunc={toggleMedicosCreateModal}
        >
          <MedicoStepperForm
            toggleMedicosCreateModal={toggleMedicosCreateModal}
            refetch={refetch}
          />
        </Modal>

        <Modal
          openState={medicoEditModal}
          closeFunc={() => setMedicoEditModal(false)}
        >
          <MedicoStepperForm
            toggleMedicoEditModal={() => setMedicoEditModal(false)}
            medicoData={medicoEditObj}
            refetch={refetch}
          />
        </Modal>
      </SectionMenu>

      <InfiniteScroll
        dataLength={medicos.length}
        next={fetchData}
        hasMore={hasMore}
        loader={<SectionLoading />}
        endMessage={<p style={{ textAlign: "center" }}>Voce chegou no final</p>}
      >
        <MedicosList items={medicos} refetch={refetch} />
      </InfiniteScroll>
    </section>
  );
};

export default Medicos;

import React from "react";
import { useDeleteUnidadeMutation } from "../../../store/services/neomedApi";

const UnidadeDeleteForm = ({
  toggleUnidadeDeleteModal,
  unidadeId,
  refetch,
}) => {
  const [deleteUnidade, { error }] = useDeleteUnidadeMutation();

  const deleteUnidadeHandler = async () => {
    await deleteUnidade({ unidadeId });

    if (!error) {
      refetch();
    }

    toggleUnidadeDeleteModal();
  };

  return (
    <div className="flex flex-col gap-9 p-4">
      <p className="self-center">Tem Certeza ?</p>
      <button
        type="button"
        className="self-center bg-primary-red rounded-md p-4 text-sm text-primary-black font-medium tracking-widest"
        onClick={deleteUnidadeHandler}
      >
        REMOVER
      </button>
    </div>
  );
};

export default UnidadeDeleteForm;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getTime } from "date-fns";
import {
  TextFieldInput,
  ButtonInput,
  TimeFieldInput,
} from "../../../components";
import {
  useCreateUsuarioMedicoMutation,
  useUpdateUsuarioMutation,
} from "../../../store/services/neomedApi";

const schema = Yup.object().shape({
  telefone: Yup.string().matches(/^[0-9()-]*$/, "Formato Errado!"),
  celular: Yup.string().matches(/^[0-9()-]*$/, "Formato Errado!"),
  estado: Yup.string().required("Campo Obrigatorio!"),
  cidade: Yup.string().required("Campo Obrigatorio!"),
  bairro: Yup.string().required("Campo Obrigatorio!"),
  rua: Yup.string().required("Campo Obrigatorio!"),
  numero: Yup.string().required("Campo Obrigatorio!"),
  cep: Yup.string()
    .matches(/^([0-9]{5})-?([0-9]{3})$/, "Formato errado!")
    .required("Campo Obrigatorio!"),
  horaInicial: Yup.number().required("Campo Obrigatorio!"),
  horaFinal: Yup.number().required("Campo Obrigatorio!"),
});

const MedicoDetailsForm = ({
  createData,
  depData,
  toggleMedicosCreateModal,
  toggleMedicoEditModal,
  medicoData,
  refetch,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      telefone: medicoData ? medicoData.telefone : "",
      celular: medicoData ? medicoData.celular : "",
      estado: medicoData ? medicoData.medicoInfo.estado : "",
      cidade: medicoData ? medicoData.medicoInfo.cidade : "",
      bairro: medicoData ? medicoData.medicoInfo.bairro : "",
      rua: medicoData ? medicoData.medicoInfo.rua : "",
      numero: medicoData ? medicoData.medicoInfo.numero : "",
      cep: medicoData ? medicoData.medicoInfo.cep : "",
      horaInicial: medicoData
        ? getTime(new Date(medicoData.medicoInfo.horaInicial))
        : getTime(new Date()),
      horaFinal: medicoData
        ? getTime(new Date(medicoData.medicoInfo.horaFinal))
        : getTime(new Date()),
    },
    resolver: yupResolver(schema),
  });

  const [createMedico] = useCreateUsuarioMedicoMutation();
  const [updateMedico] = useUpdateUsuarioMutation();
  const [isDisabled, setIsDisabled] = useState(false);

  const createMedicoHandler = async (data) => {
    setIsDisabled(true);

    const usuarioObj = {
      email: createData.email,
      password: createData.password,
      nome: depData.nome,
      telefone: data.telefone,
      celular: data.celular,
      medicoInfo: {
        cpf: depData.cpf,
        rg: depData.rg,
        crm: depData.crm,
        experiencia: depData.experiencia,
        genero: depData.genero,
        nascimento: depData.nascimento,
        preco: depData.preco,
        horaInicial: data.horaInicial,
        horaFinal: data.horaFinal,
        estado: data.estado,
        cidade: data.cidade,
        bairro: data.bairro,
        rua: data.rua,
        numero: data.numero,
        cep: data.cep,
      },
    };

    const { data: response } = await createMedico({
      unidadeId: depData.unidadeRef,
      especialidadeId: depData.especialidadeRef,
      usuarioObj,
    });

    if (response?.status === "success") {
      refetch();
    }

    toggleMedicosCreateModal();
  };

  const editMedicoHandler = async (data) => {
    setIsDisabled(true);

    const usuarioObj = {
      nome: depData.nome,
      telefone: data.telefone,
      celular: data.celular,
      medicoInfo: {
        cpf: depData.cpf,
        rg: depData.rg,
        crm: depData.crm,
        experiencia: depData.experiencia,
        genero: depData.genero,
        nascimento: depData.nascimento,
        preco: depData.preco,
        horaInicial: data.horaInicial,
        horaFinal: data.horaFinal,
        estado: data.estado,
        cidade: data.cidade,
        bairro: data.bairro,
        rua: data.rua,
        numero: data.numero,
        cep: data.cep,
      },
    };

    const { data: response } = await updateMedico({
      usuarioId: medicoData._id,
      usuarioObj,
    });

    if (response?.status === "success") {
      refetch();
    }

    toggleMedicoEditModal();
  };

  return (
    <form
      onSubmit={
        medicoData
          ? handleSubmit(editMedicoHandler)
          : handleSubmit(createMedicoHandler)
      }
      className="grid normal:grid-cols-3 small:grid-cols-2 phone:grid-cols-1 gap-9 p-4"
    >
      <TextFieldInput
        name={"estado"}
        control={control}
        label="Estado"
        error={errors?.estado}
      />

      <TextFieldInput
        name={"cidade"}
        control={control}
        label="Cidade"
        error={errors?.cidade}
      />

      <TextFieldInput
        name={"bairro"}
        control={control}
        label="Bairro"
        type={"text"}
        error={errors?.bairro}
      />

      <TextFieldInput
        name={"rua"}
        control={control}
        label="Rua"
        error={errors?.rua}
      />

      <TextFieldInput
        name={"numero"}
        control={control}
        label="Numero"
        error={errors?.numero}
      />

      <TextFieldInput
        name={"cep"}
        control={control}
        label="Cep"
        error={errors?.cep}
        mask={"99999-999"}
      />

      <TextFieldInput
        name={"telefone"}
        control={control}
        label="Telefone"
        error={errors?.telefone}
        mask={"(99)9999-9999"}
      />

      <TextFieldInput
        name={"celular"}
        control={control}
        label="Celular"
        error={errors?.celular}
        mask={"(99)99999-9999"}
      />

      <TimeFieldInput
        name={"horaInicial"}
        control={control}
        error={errors?.horaInicial}
        label="Hora Inicial"
      />

      <TimeFieldInput
        name={"horaFinal"}
        control={control}
        error={errors?.horaFinal}
        label="Hora Final"
      />

      <ButtonInput
        styles={"col-span-full justify-self-center"}
        type={"submit"}
        disabled={isDisabled}
      >
        {medicoData ? "EDITAR" : "FINALIZAR"}
      </ButtonInput>
    </form>
  );
};

export default MedicoDetailsForm;

import React, { useState } from "react";
import { endOfDay, getTime, startOfDay } from "date-fns";
import { Menu, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/solid";
import DaySelector from "./DaySelector/DaySelector";
import DateSelector from "./DateSelector/DateSelector";

const SearchDateFieldInput = ({ queryHandler, setEditModal, setEditObj }) => {
  const [step, setStep] = useState(0);
  const [startQuery, setStartQuery] = useState(null);
  const [endQuery, setEndQuery] = useState(null);

  const selectDayHandler = (day) => {
    setStartQuery(getTime(startOfDay(day)));
    setEndQuery(getTime(endOfDay(day)));

    setStep(1);
  };

  const handleSelect = (model) => {
    setEditModal();
    setEditObj(model);
  };

  return (
    <Menu as={"div"} className="relative">
      <Menu.Button className={"w-full"}>
        <div className="relative h-14 border-none rounded-md bg-white text-primary-black shadow-md">
          <input
            className="outline-none w-full h-full rounded-md bg-transparent text-sm transition-all duration-200 ease-in-out px-2 py-8"
            type={"text"}
            readOnly
            id="calendar"
          />

          <CalendarIcon className="w-5 text-primary-black absolute top-2/4 right-2 transform -translate-y-2/4 cursor-pointer" />

          <label
            className="absolute top-2/4 left-2 transform -translate-y-2/4 text-primary-black text-opacity-50 p-2 text-sm"
            htmlFor="calendar"
          >
            Data
          </label>
        </div>
      </Menu.Button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        afterLeave={() => setStep(0)}
      >
        <Menu.Items
          className={"absolute top-16 left-2/4 -translate-x-2/4 z-10"}
        >
          {step === 0 && <DaySelector selectDayHandler={selectDayHandler} />}
          {step === 1 && (
            <div className="w-80 h-52 p-2 bg-white rounded-md shadow-xl">
              <DateSelector
                queryHandler={queryHandler}
                startQuery={startQuery}
                endQuery={endQuery}
                handleSelect={handleSelect}
              />
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default SearchDateFieldInput;

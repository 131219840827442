import React, { useContext } from "react";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context";

const Header = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  return (
    <header className="max-w-7xl mx-auto my-0 px-8 py-0 h-20 flex justify-between items-center">
      <img
        className="h-14 hover:cursor-pointer"
        alt="Logo"
        src="/assets/Logo_Icon_SVG.svg"
        onClick={() => navigate("/")}
      />
      <div className="flex gap-4 phone:hidden">
        {auth?.user?.tipo === "9999" && (
          <nav className="flex gap-10">
            <button
              onClick={() => navigate("/especializacoes")}
              title="Especialidades"
              className={
                " text-primary-black rounded-xl hover:text-shade-green transition-all duration-150"
              }
            >
              <span>Especialidades</span>
            </button>

            <button
              onClick={() => navigate("/medicos")}
              title="Medicos"
              className={
                "text-primary-black rounded-xl hover:text-shade-green transition-all duration-150"
              }
            >
              <span>Medicos</span>
            </button>

            <button
              onClick={() => navigate("/pacientes")}
              title="Pacientes"
              className={
                " text-primary-black hover:text-shade-green transition-all duration-150"
              }
            >
              <span>Pacientes</span>
            </button>

            <button
              onClick={() => navigate("/admins")}
              title="Admins"
              className={
                " text-primary-black hover:text-shade-green transition-all duration-150"
              }
            >
              <span>Admins</span>
            </button>
          </nav>
        )}
      </div>

      <HeaderMenu />
    </header>
  );
};
export default Header;

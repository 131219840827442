import React, { useState } from "react";
import { Stepper } from "../../../components";
import UnidadeCreateForm from "../UnidadeCreateForm/UnidadeCreateForm";
import UnidadeDetailsForm from "../UnidadeDetailsForm/UnidadeDetailsForm";

const UnidadeStepperForm = ({
  toggleUnidadeCreateModal,
  toggleUnidadeEditModal,
  unidadeData,
  refetch,
}) => {
  const [stepNumber, setStepNumber] = useState(0);
  const [stepOneData, setStepOneData] = useState({});

  const next = (stepOneData) => {
    setStepNumber((prevState) => prevState + 1);
    setStepOneData(stepOneData);
  };

  return (
    <Stepper
      stepNumber={stepNumber}
      labels={["Identificação & Horarios", "Contatos & Endereco"]}
    >
      <UnidadeCreateForm next={next} unidadeData={unidadeData} />
      <UnidadeDetailsForm
        stepOneData={stepOneData}
        toggleUnidadeCreateModal={toggleUnidadeCreateModal}
        toggleUnidadeEditModal={toggleUnidadeEditModal}
        unidadeData={unidadeData}
        refetch={refetch}
      />
    </Stepper>
  );
};

export default UnidadeStepperForm;

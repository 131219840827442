import React from "react";
import { usePeer, PeerContext } from "./context";

const StartPeer = ({ nome, medico, agendamentoId, children }) => {
  const { currentUser, localStream, participants } = usePeer(
    nome,
    medico,
    agendamentoId
  );

  return (
    <PeerContext.Provider value={{ currentUser, localStream, participants }}>
      {children}
    </PeerContext.Provider>
  );
};

export default StartPeer;

import { useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../context";

const RequireAuth = ({ allowedRole }) => {
  const auth = useContext(AuthContext);
  const location = useLocation();

  return (
    <>
      {allowedRole.includes(auth?.user?.tipo) && auth?.isLoading === false ? (
        <Outlet />
      ) : auth?.user && auth?.isLoading === false ? (
        <Navigate to="notfound" state={{ from: location }} replace />
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </>
  );
};

export default RequireAuth;

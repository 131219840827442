import React from "react";
import { Routes, Route } from "react-router-dom";
import { NoAuth, RequireAuth, Root, PublicAuth } from "./layouts";
import {
  Unidades,
  Especializacoes,
  Medicos,
  Pacientes,
  Admins,
  Login,
  Perfil,
  Error,
  Home,
  Agendamentos,
  MeetStarter,
  Prontuario,
  Reset,
} from "./pages";

const App = () => (
  <Routes>
    <Route path="/" element={<Root />}>
      {/* no auth routes */}
      <Route element={<NoAuth />}>
        <Route path="login" element={<Login />} />
        <Route path="reset" element={<Reset />} />
      </Route>

      {/* public routes */}
      <Route element={<PublicAuth />}>
        <Route path="agendamentos/:id" element={<MeetStarter />} />
      </Route>

      {/* Protected Routes */}
      <Route element={<RequireAuth allowedRole={["8181", "9999"]} />}>
        <Route path="/" element={<Home />} />
        <Route path="perfil" element={<Perfil />} />
        <Route path="agendamentos" element={<Agendamentos />} />

        <Route element={<RequireAuth allowedRole={["8181"]} />}>
          <Route path="pacientes/:id/prontuario" element={<Prontuario />} />
        </Route>

        <Route element={<RequireAuth allowedRole={["9999"]} />}>
          <Route path="unidades" element={<Unidades />} />
          <Route path="especializacoes" element={<Especializacoes />} />
          <Route path="pacientes" element={<Pacientes />} />
          <Route path="medicos" element={<Medicos />} />
          <Route path="admins" element={<Admins />} />
        </Route>
      </Route>

      {/* not found */}
      <Route path="*" element={<Error />} />
    </Route>
  </Routes>
);

export default App;

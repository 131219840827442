import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextFieldInput, ButtonInput } from "../../../components";

const schema = Yup.object().shape({
  email: Yup.string().email("Formato Errado!").required("Campo Obrigatório"),
  password: Yup.string()
    .min(6, "Campo deve ter no mínimo 6 letras")
    .required("Campo Obrigatório"),
  passwordConfirm: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Senhas não estão iguais!"
  ),
});

const AdminCreateForm = ({ next }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: { email: "", password: "", passwordConfirm: "" },
    resolver: yupResolver(schema),
  });

  const createAdminUser = async (data) => {
    next({ ...data });
  };

  return (
    <form
      onSubmit={handleSubmit(createAdminUser)}
      className="grid grid-cols-1 gap-9 p-4"
    >
      <TextFieldInput
        name={"email"}
        control={control}
        label="Email"
        error={errors?.email}
      />

      <TextFieldInput
        name={"password"}
        control={control}
        label="Senha"
        error={errors?.password}
        type="password"
      />

      <TextFieldInput
        name={"passwordConfirm"}
        control={control}
        label="Confirmar Senha"
        error={errors?.passwordConfirm}
        type="password"
      />

      <ButtonInput styles={"col-span-full justify-self-center"} type={"submit"}>
        PROXIMO
      </ButtonInput>
    </form>
  );
};

export default AdminCreateForm;

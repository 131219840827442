import React from "react";
import { RichUtils } from "draft-js";
import "draft-js/dist/Draft.css";

const blockTypeButtons = [
  {
    value: "H1",
    block: "header-one",
  },

  {
    value: "H2",
    block: "header-two",
  },

  {
    value: "H3",
    block: "header-three",
  },

  {
    value: "UL",
    block: "unordered-list-item",
  },
];

const styleTypeButtons = [
  {
    value: "Bold",
    style: "BOLD",
  },
  {
    value: "Italic",
    style: "ITALIC",
  },
  {
    value: "Underline",
    style: "UNDERLINE",
  },
];

const TextEditorHeader = ({ editorState, setEditorState }) => {
  const toggleBlockType = (block) => {
    const newState = RichUtils.toggleBlockType(editorState, block);

    if (newState) {
      setEditorState(newState);
    }
  };

  const toggleStyleType = (style) => {
    const newState = RichUtils.toggleInlineStyle(editorState, style);

    if (newState) {
      setEditorState(newState);
    }
  };

  const renderBlockButton = (value, block) => {
    return <button onClick={() => toggleBlockType(block)}>{value}</button>;
  };

  const renderStyleButton = (value, style) => {
    return <button onClick={() => toggleStyleType(style)}>{value}</button>;
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-7">
        {blockTypeButtons.map((button, index) => {
          return (
            <div key={index}>
              {renderBlockButton(button.value, button.block)}
            </div>
          );
        })}
      </div>

      <div className="flex gap-7">
        {styleTypeButtons.map((button, index) => {
          return (
            <div key={index}>
              {renderStyleButton(button.value, button.style)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TextEditorHeader;

import React from "react";
import { Menu } from "@headlessui/react";
import { format } from "date-fns";

const DateSelector = ({ queryHandler, startQuery, endQuery, handleSelect }) => {
  const { isLoading, data: response } = queryHandler({
    paramsObj: { start: startQuery, end: endQuery, sort: "-data" },
  });

  return (
    <>
      {isLoading && (
        <div className="relative w-full h-full flex justify-center items-center">
          <div className="border-t-transparent w-16 h-16 border-4 border-primary-green border-solid rounded-full animate-spin" />
        </div>
      )}

      <ul className="flex flex-col gap-2 overflow-hidden overflow-y-auto no-scrollbar">
        {!isLoading &&
          response?.results !== 0 &&
          response?.data?.docs?.map((agendamento) => (
            <Menu.Item key={agendamento._id}>
              {({ active }) => (
                <li
                  className={`flex items-center w-full h-12 rounded-md cursor-pointer ${
                    active && "bg-light-green"
                  }`}
                  onClick={() => handleSelect(agendamento)}
                >
                  {
                    <p className="text-primary-black font-medium">
                      {format(
                        new Date(agendamento.data),
                        "dd-MM-yyyy HH:mm:ss"
                      )}
                    </p>
                  }
                </li>
              )}
            </Menu.Item>
          ))}
      </ul>
    </>
  );
};

export default DateSelector;

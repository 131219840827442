import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon, PlusIcon } from "@heroicons/react/solid";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  SectionTitle,
  SectionMenu,
  Modal,
  SearchFieldInput,
  SectionLoading,
} from "../../components";
import PacientesList from "./PacientesList/PacientesList";
import { useGetAllUsuariosQuery } from "../../store/services/neomedApi";
import PacienteStepperForm from "./PacienteStepperForm/PacienteStepperForm";

const Pacientes = () => {
  const [pacientes, setPacientes] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [pacientesCreateModal, setPacientesCreateModal] = useState(false);
  const [pacienteEditModal, setPacienteEditModal] = useState(false);
  const [pacienteEditObj, setPacienteEditObj] = useState({});
  const { data: response, refetch } = useGetAllUsuariosQuery({
    paramsObj: { page, limit: "8", tipo: 1199 },
  });

  const navigate = useNavigate();

  const togglePacientesCreateModal = () => {
    setPacientesCreateModal((prevState) => !prevState);
  };

  const fetchData = () => {
    setPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (response && response.results !== 0) {
      setPacientes((prevState) => {
        const array = prevState.filter((item) => {
          const index = response?.data?.docs.findIndex(
            (doc) => doc._id === item._id
          );

          if (index === -1) {
            return true;
          }

          return false;
        });

        const toBeRendered = [...array, ...response?.data?.docs];

        return toBeRendered;
      });

      if (response.results === 8) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } else {
      setHasMore(false);
    }
  }, [response, response?.results, response?.data?.docs]);

  return (
    <section className="pt-6">
      <SectionTitle>Pacientes</SectionTitle>

      <SectionMenu styles="justify-between">
        <button type="button" onClick={() => navigate(-1)}>
          <ArrowLeftIcon className="w-6 text-shade-black" />
        </button>

        <SearchFieldInput
          queryHandler={useGetAllUsuariosQuery}
          queryParams={{ tipo: 1199 }}
          setEditModal={() => setPacienteEditModal(true)}
          setEditObj={setPacienteEditObj}
        />

        <button type="button" onClick={togglePacientesCreateModal}>
          <PlusIcon className="w-6 text-shade-black" />
        </button>

        <Modal
          openState={pacientesCreateModal}
          closeFunc={togglePacientesCreateModal}
        >
          <PacienteStepperForm
            togglePacientesCreateModal={togglePacientesCreateModal}
            refetch={refetch}
          />
        </Modal>

        <Modal
          openState={pacienteEditModal}
          closeFunc={() => setPacienteEditModal(false)}
        >
          <PacienteStepperForm
            togglePacienteEditModal={() => setPacienteEditModal(false)}
            pacienteData={pacienteEditObj}
            refetch={refetch}
          />
        </Modal>
      </SectionMenu>

      <InfiniteScroll
        dataLength={pacientes.length}
        next={fetchData}
        hasMore={hasMore}
        loader={<SectionLoading />}
        endMessage={<p style={{ textAlign: "center" }}>Voce chegou no final</p>}
      >
        <PacientesList items={pacientes} refetch={refetch} />
      </InfiniteScroll>
    </section>
  );
};

export default Pacientes;

import React, { useState } from "react";
import { Stepper } from "../../../components";
import MedicoCreateForm from "../MedicoCreateForm/MedicoCreateForm";
import MedicoDepForm from "../MedicoDepForm/MedicoDepForm";
import MedicoDetailsForm from "../MedicoDetailsForm/MedicoDetailsForm";

const MedicoStepperForm = ({
  toggleMedicosCreateModal,
  toggleMedicoEditModal,
  refetch,
  medicoData,
}) => {
  const [stepNumber, setStepNumber] = useState(0);
  const [createData, setCreateData] = useState({});
  const [depData, setDepData] = useState({});
  const labels = medicoData
    ? ["Identidade & Documentos", "Detalhes"]
    : ["Cadastro", "Identidade & Documentos", "Detalhes"];

  const createFormHandler = (data) => {
    setStepNumber((prevState) => prevState + 1);
    setCreateData(data);
  };

  const depFormHandler = (data) => {
    setStepNumber((prevState) => prevState + 1);
    setDepData(data);
  };

  return (
    <Stepper stepNumber={stepNumber} labels={labels}>
      {!medicoData && <MedicoCreateForm next={createFormHandler} />}

      <MedicoDepForm depFormHandler={depFormHandler} medicoData={medicoData} />

      <MedicoDetailsForm
        createData={createData}
        depData={depData}
        toggleMedicosCreateModal={toggleMedicosCreateModal}
        toggleMedicoEditModal={toggleMedicoEditModal}
        medicoData={medicoData}
        refetch={refetch}
      />
    </Stepper>
  );
};

export default MedicoStepperForm;

import React, { useState, useEffect, Fragment } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { useGetAllUsuariosQuery } from "../../../store/services/neomedApi";

const PacienteSelector = ({ next }) => {
  const [search, setSearch] = useState(null);
  const [query, setQuery] = useState(null);
  const [pacientes, setPacientes] = useState([]);
  const { isLoading, data: response } = useGetAllUsuariosQuery(
    {
      paramsObj: { nome: query, tipo: 1199 },
    },
    { skip: !query ? true : false }
  );

  const handleFilter = (event) => {
    const search = event.target.value;

    if (!search) {
      setSearch(null);
      setPacientes([]);
    } else {
      setSearch(search);
    }
  };

  const handleSelect = (paciente) => {
    next(paciente._id);
  };

  useEffect(() => {
    const render = () => {
      const timeOutId = setTimeout(async () => {
        if (search) {
          setQuery(search);
        }
      }, 1500);

      return timeOutId;
    };

    const timeOutId = render();

    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    if (response?.results === 0) {
      setPacientes([]);
    } else {
      setPacientes(response?.data?.docs);
    }
  }, [response]);

  return (
    <div className="flex justify-center">
      <div className="w-80">
        <Combobox onChange={handleSelect}>
          <div className="relative mt-1">
            <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left shadow-md">
              <Combobox.Input
                className={
                  "w-full h-14 border-none text-base text-primary-black pl-4 focus:outline-none"
                }
                onChange={handleFilter}
                displayValue={(user) => user?.nome}
                placeholder="Pacientes"
              />
            </div>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setPacientes([])}
            >
              <Combobox.Options
                className={
                  "flex flex-col gap-2 w-80 h-52 p-2 bg-white rounded-md shadow-xl overflow-hidden overflow-y-auto no-scrollbar absolute top-16 left-2/4 -translate-x-2/4"
                }
              >
                {isLoading && (
                  <div className="relative w-full h-full flex justify-center items-center">
                    <div className="border-t-transparent w-16 h-16 border-4 border-primary-green border-solid rounded-full animate-spin" />
                  </div>
                )}

                {!isLoading &&
                  pacientes?.length !== 0 &&
                  pacientes?.map((user) => (
                    <Combobox.Option
                      key={user._id}
                      className={({ active }) =>
                        `relative flex items-center w-full h-12 text-primary-black rounded-md cursor-pointer ${
                          active && "bg-light-green"
                        }`
                      }
                      value={user}
                    >
                      {() => (
                        <p className="text-base font-medium">{user?.nome}</p>
                      )}
                    </Combobox.Option>
                  ))}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  );
};

export default PacienteSelector;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextFieldInput, ButtonInput } from "../../../components";
import {
  useCreateUsuarioPacienteMutation,
  useUpdateUsuarioMutation,
} from "../../../store/services/neomedApi";

const schema = Yup.object().shape({
  telefone: Yup.string().matches(/^[0-9()-]*$/, "Formato Errado!"),
  celular: Yup.string().matches(/^[0-9()-]*$/, "Formato Errado!"),
  estado: Yup.string().required("Campo Obrigatorio!"),
  cidade: Yup.string().required("Campo Obrigatorio!"),
  bairro: Yup.string().required("Campo Obrigatorio!"),
  rua: Yup.string().required("Campo Obrigatorio!"),
  numero: Yup.string().required("Campo Obrigatorio!"),
  cep: Yup.string()
    .matches(/^([0-9]{5})-?([0-9]{3})$/, "Formato errado!")
    .required("Campo Obrigatorio!"),
});

const PacienteDetailsForm = ({
  stepOneData,
  togglePacienteCreateModal,
  togglePacienteEditModal,
  pacienteData,
  refetch,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      telefone: pacienteData ? pacienteData.telefone : "",
      celular: pacienteData ? pacienteData.celular : "",
      estado: pacienteData ? pacienteData.pacienteInfo.estado : "",
      bairro: pacienteData ? pacienteData.pacienteInfo.bairro : "",
      cidade: pacienteData ? pacienteData.pacienteInfo.cidade : "",
      rua: pacienteData ? pacienteData.pacienteInfo.rua : "",
      numero: pacienteData ? pacienteData.pacienteInfo.numero : "",
      cep: pacienteData ? pacienteData.pacienteInfo.cep : "",
    },
    resolver: yupResolver(schema),
  });
  const [createPaciente] = useCreateUsuarioPacienteMutation();
  const [updatePaciente] = useUpdateUsuarioMutation();
  const [isDisabled, setIsDisabled] = useState(false);

  const createPacienteHandler = async (data) => {
    setIsDisabled(true);

    const usuarioObj = {
      nome: stepOneData.nome,
      telefone: data.telefone,
      celular: data.celular,
      pacienteInfo: {
        cpf: stepOneData.cpf,
        rg: stepOneData.rg,
        nacionalidade: stepOneData.nacionalidade,
        nascimento: stepOneData.nascimento,
        cns: stepOneData.cns,
        genero: stepOneData.genero,
        convenio: stepOneData.convenio,
        encPor: stepOneData.encPor,
        estado: data.estado,
        cidade: data.cidade,
        bairro: data.bairro,
        rua: data.rua,
        numero: data.numero,
        cep: data.cep,
      },
    };

    const { data: response } = await createPaciente({ usuarioObj });

    if (response?.status === "success") {
      refetch();
    }

    togglePacienteCreateModal();
  };

  const editPacienteHandler = async (data) => {
    setIsDisabled(true);

    const usuarioObj = {
      nome: stepOneData.nome,
      telefone: data.telefone,
      celular: data.celular,
      pacienteInfo: {
        cpf: stepOneData.cpf,
        rg: stepOneData.rg,
        nacionalidade: stepOneData.nacionalidade,
        nascimento: stepOneData.nascimento,
        cns: stepOneData.cns,
        genero: stepOneData.genero,
        convenio: stepOneData.convenio,
        encPor: stepOneData.encPor,
        estado: data.estado,
        cidade: data.cidade,
        bairro: data.bairro,
        rua: data.rua,
        numero: data.numero,
        cep: data.cep,
      },
    };

    const { data: response } = await updatePaciente({
      usuarioId: pacienteData._id,
      usuarioObj,
    });

    if (response?.status === "success") {
      refetch();
    }

    togglePacienteEditModal();
  };

  return (
    <form
      onSubmit={
        pacienteData
          ? handleSubmit(editPacienteHandler)
          : handleSubmit(createPacienteHandler)
      }
      className="grid normal:grid-cols-2 small:grid-cols-2 phone:grid-cols-1 gap-9 p-4"
    >
      <TextFieldInput
        name={"telefone"}
        control={control}
        label="Telefone"
        type={"text"}
        error={errors?.telefone}
        mask={"(99)9999-9999"}
      />

      <TextFieldInput
        name={"celular"}
        control={control}
        label="Celular"
        type={"text"}
        error={errors?.celular}
        mask={"(99)99999-9999"}
      />

      <TextFieldInput
        name={"estado"}
        control={control}
        label="Estado"
        error={errors?.estado}
      />

      <TextFieldInput
        name={"cidade"}
        control={control}
        label="Cidade"
        error={errors?.cidade}
      />

      <TextFieldInput
        name={"bairro"}
        control={control}
        label="Bairro"
        type={"text"}
        error={errors?.bairro}
      />

      <TextFieldInput
        name={"rua"}
        control={control}
        label="Rua"
        error={errors?.rua}
      />

      <TextFieldInput
        name={"numero"}
        control={control}
        label="Numero"
        error={errors?.numero}
      />

      <TextFieldInput
        name={"cep"}
        control={control}
        label="Cep"
        error={errors?.cep}
        mask={"99999-999"}
      />

      <ButtonInput
        styles={"col-span-full justify-self-center"}
        type={"submit"}
        disabled={isDisabled}
      >
        {pacienteData ? "EDITAR" : "FINALIZAR"}
      </ButtonInput>
    </form>
  );
};

export default PacienteDetailsForm;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { TextFieldInput, ButtonInput, SelectInput } from "../../../components";
import {
  useGetAllUnidadesQuery,
  useCreateUsuarioAdminMutation,
  useUpdateUsuarioMutation,
} from "../../../store/services/neomedApi";

const schema = Yup.object().shape({
  nome: Yup.string().required("Campo Obrigatório"),
  telefone: Yup.string().matches(/^[0-9()-]*$/, "Formato Errado!"),
  celular: Yup.string().matches(/^[0-9()-]*$/, "Formato Errado!"),
  unidadeRef: Yup.string().matches(/^[A-Za-z0-9]+$/i, "Formato errado!"),
});

const AdminDetailsForm = ({
  stepOneData,
  toggleAdminsCreateModal,
  toggleAdminEditModal,
  adminData,
  refetch,
}) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      nome: adminData ? adminData.nome : "",
      telefone: adminData ? adminData.telefone : "",
      celular: adminData ? adminData.celular : "",
      unidadeRef: adminData ? adminData.unidadeRef._id : "",
    },
    resolver: yupResolver(schema),
  });
  const { data: unidades } = useGetAllUnidadesQuery({
    paramsObj: { limit: "100" },
  });

  const [createAdmin] = useCreateUsuarioAdminMutation();
  const [updateAdmin] = useUpdateUsuarioMutation();
  const [isDisabled, setIsDisabled] = useState(false);

  const adminDetailsHandler = async (data) => {
    setIsDisabled(true);

    const usuarioObj = {
      email: stepOneData.email,
      password: stepOneData.password,
      nome: data.nome,
      telefone: data.telefone,
      celular: data.celular,
    };

    const { data: response } = await createAdmin({
      unidadeId: data.unidadeRef,
      usuarioObj,
    });

    if (response?.status === "success") {
      refetch();
    }

    toggleAdminsCreateModal();
  };

  const editAdminHandler = async (data) => {
    setIsDisabled(true);

    const usuarioObj = {
      nome: data.nome,
      telefone: data.telefone,
      celular: data.celular,
    };

    const { data: response } = await updateAdmin({
      usuarioId: adminData._id,
      usuarioObj,
    });

    if (response?.status === "success") {
      refetch();
    }

    toggleAdminEditModal();
  };

  return (
    <form
      onSubmit={
        adminData
          ? handleSubmit(editAdminHandler)
          : handleSubmit(adminDetailsHandler)
      }
      className="grid grid-cols-1 gap-9 p-4"
    >
      <TextFieldInput
        name={"nome"}
        control={control}
        label="Nome"
        error={errors?.nome}
      />

      <TextFieldInput
        name={"telefone"}
        control={control}
        label="Telefone: DDD..."
        error={errors?.telefone}
        mask={"(99)9999-9999"}
      />

      <TextFieldInput
        name={"celular"}
        control={control}
        label="Celular: DDD..."
        error={errors?.celular}
        mask={"(99)99999-9999"}
      />

      <SelectInput
        name="unidadeRef"
        label="Unidades"
        control={control}
        error={errors?.unidadeRef}
      >
        {control._defaultValues.unidadeRef === "" && <option value="none" />}
        {unidades?.data?.docs?.map((unidade, index) => (
          <option key={index} value={unidade._id}>
            {unidade.nome}
          </option>
        ))}
      </SelectInput>

      <ButtonInput
        styles={"col-span-full justify-self-center"}
        type={"submit"}
        disabled={isDisabled}
      >
        {adminData ? "EDITAR" : "FINALIZAR"}
      </ButtonInput>
    </form>
  );
};

export default AdminDetailsForm;

import React, { useState } from "react";
import { Controller } from "react-hook-form";

const SelectInput = ({ name, label, control, error, children }) => {
  const [active, setActive] = useState(
    control._defaultValues[name] !== "" ? true : false
  );

  const handleActivation = (e) => {
    setActive(!!e.target.value);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={control._defaultValues[name] === "" && "none"}
      render={({ field: { onChange, value } }) => (
        <div
          className={`relative h-14 border rounded-md bg-white text-primary-black border-opacity-25 ${
            error ? "border-primary-red" : "border-slate-900"
          }`}
        >
          <select
            value={value}
            onChange={(newValue) => {
              handleActivation(newValue);
              onChange(newValue);
            }}
            className="outline-none w-full h-full rounded-md bg-transparent text-sm transition-all duration-200 ease-in-out p-1"
          >
            {children}
          </select>

          <label
            className={`absolute flex items-center text-primary-black text-opacity-50 p-2 transition-all duration-200 ease-in-out ${
              active
                ? "text-xs -top-1 left-0"
                : "text-sm top-2/4 transform -translate-y-2/4"
            }`}
            htmlFor={name}
          >
            {label}
          </label>

          {error && (
            <p className="absolute -bottom-6 left-0 text-sm text-primary-red">
              {error.message}
            </p>
          )}
        </div>
      )}
    />
  );
};
export default SelectInput;

import React, { useState, useEffect, useRef } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import {
  addMonths,
  endOfDay,
  format,
  parseISO,
  subMonths,
  isBefore,
  subYears,
  addYears,
  setYear,
} from "date-fns";

const Header = ({ value, onChange }) => {
  const [yearsMenu, setYearsMenu] = useState(false);
  const [yearsOptions, setYearsOptions] = useState([]);
  const yearsMenuRef = useRef();

  const currMonth = () => {
    return format(parseISO(value), "MM");
  };

  const currYear = () => {
    return format(parseISO(value), "yyyy");
  };

  const prevMonth = () => {
    const prevMonthDate = subMonths(parseISO(value), 1);

    return format(endOfDay(prevMonthDate), "yyyy-MM-dd'T'HH:mm:ss");
  };

  const nextMonth = () => {
    const nextMonthDate = addMonths(parseISO(value), 1);

    return format(endOfDay(nextMonthDate), "yyyy-MM-dd'T'HH:mm:ss");
  };

  const changeYear = (year) => {
    const changedYearDate = setYear(parseISO(value), year);

    return format(changedYearDate, "yyyy-MM-dd'T'HH:mm:ss");
  };

  useEffect(() => {
    let startYear = subYears(new Date(), 110);
    const years = [];

    while (isBefore(startYear, new Date())) {
      years.push(format(startYear, "yyyy"));
      startYear = addYears(startYear, 1);
    }

    setYearsOptions(years);
  }, []);

  useEffect(() => {
    const handler = (e) => {
      if (!yearsMenuRef.current.contains(e.target)) {
        setYearsMenu(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  return (
    <div className="flex flex-col gap-4 w-full bg-primary-green px-4 py-2">
      <div className="relative self-center" ref={yearsMenuRef}>
        <p
          className="text-sm font-medium text-primary-black cursor-pointer p-2 rounded-md hover:bg-light-green"
          onClick={() => setYearsMenu((prevState) => !prevState)}
        >
          {currYear()}
        </p>

        <div
          className={`flex flex-col gap-2 w-28 h-40 py-2 bg-light-green rounded-md shadow-md overflow-hidden overflow-y-auto no-scrollbar absolute top-16 left-2/4 transform -translate-x-2/4 -translate-y-5 transition-all duration-200 ease-out ${
            yearsMenu
              ? "opacity-100 pointer-events-auto visible translate-y-0"
              : "opacity-0 pointer-events-none invisible"
          }`}
        >
          {yearsOptions.map((year, index) => (
            <p
              key={index}
              className="text-base font-normal rounded-md px-2 cursor-pointer hover:bg-primary-green hover:text-light-green"
              onClick={() => onChange(changeYear(year))}
            >
              {year}
            </p>
          ))}
        </div>
      </div>

      <div className="flex items-center justify-between">
        <button type="button" onClick={() => onChange(prevMonth)}>
          <ArrowLeftIcon className="w-4 text-primary-black" />
        </button>

        <p className="text-sm font-medium text-primary-black">{currMonth()}</p>

        <button type="button" onClick={() => onChange(nextMonth)}>
          <ArrowRightIcon className="w-4 text-primary-black" />
        </button>
      </div>
    </div>
  );
};

export default Header;

import React, { useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  DotsHorizontalIcon,
  PencilIcon,
  ClipboardListIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context";

const PacienteMenu = ({
  showModalEditFunc,
  pacienteRef,
  togglePacienteDeleteModal,
}) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  return (
    <Menu as="div" className="relative">
      <Menu.Button>
        <DotsHorizontalIcon className="w-5 text-shade-green hover:text-primary-green transition-all duration-50 ease-out" />
      </Menu.Button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="flex flex-col bg-white shadow-md rounded-lg w-56 px-1 py-1 absolute right-0 z-10">
          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                onClick={showModalEditFunc}
                className={`${
                  active
                    ? "bg-primary-green text-gray-50"
                    : "text-primary-black"
                } flex items-center gap-2 rounded-md px-1 py-1 `}
              >
                <PencilIcon className="w-5 fill-current" />
                <span>Editar</span>
              </button>
            )}
          </Menu.Item>

          {auth?.user?.tipo === "8181" && (
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  title="Editar"
                  onClick={() => {
                    navigate(`/pacientes/${pacienteRef}/prontuario`);
                  }}
                  className={`${
                    active
                      ? "bg-primary-green text-gray-50"
                      : "text-primary-black"
                  } flex items-center gap-2 rounded-md px-1 py-1 `}
                >
                  <ClipboardListIcon className="w-5 fill-current" />
                  <span>Prontuário do Paciente</span>
                </button>
              )}
            </Menu.Item>
          )}

          <Menu.Item>
            {({ active }) => (
              <button
                type="button"
                title="Remover"
                onClick={togglePacienteDeleteModal}
                className={`${
                  active
                    ? "bg-primary-green text-gray-50"
                    : "text-primary-black"
                } flex items-center gap-2 rounded-md px-1 py-1 `}
              >
                <TrashIcon className="w-5 fill-current" />
                <span>Remover</span>
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default PacienteMenu;
